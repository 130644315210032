import { Route, Routes } from "react-router-dom";
import About from "../Components/Pages/About";
import CartPage from "../Components/Pages/CartPage";
import Address from "../Components/Pages/CartPage/Address";
import Checkout from "../Components/Pages/Checkout/Checkout";
import Contact from "../Components/Pages/Contact/Index";
import Home from "../Components/Pages/Home";
import Productdetail from "../Components/Pages/Product-Details/Productdetail";
import Product from "../Components/Pages/Products";
import ChangePassword from "../Components/PostLogin/ChangePassword";
import MyAddress from "../Components/PostLogin/MyAddress";
import MyOrder from "../Components/PostLogin/MyOrder";
import Myprofile from "../Components/PostLogin/Myprofile";


function PageRouting() {
    return (
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/product' element={< Product/>} />
        <Route path='/productdetail/:id' element={<Productdetail />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<CartPage />} />
        <Route path='/address' element={<Address />} />
        <Route path='/myaddress' element={<MyAddress/>}/>
        <Route path='/myprofile' element={<Myprofile/>}/>
        <Route path='/myorders' element={<MyOrder />}/>
        <Route path="/changepassword" element={<ChangePassword />} />
  
      </Routes>
    );
  }
  
  export default PageRouting;