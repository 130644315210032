import React from 'react'
import Header from '../../Shared/Header'
import Dheader from '../../Shared/Dheader'
import Footer from '../../Shared/Footer'

const Layouts = ({ children }) => {
  return (
    <div >
      <Dheader />
      <Header />
      <div>
        {children}
      </div>
      <Footer/>
    </div>


  )
}

export default Layouts











