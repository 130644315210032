import { message } from "antd";
import http from "./http_common";

class AppointmentService{
   async bookAppointment(payload){
        try{
           await http.post('/bookAppointment/',payload);
            message.success("Thank You! Our team will contact you shortly.");
        }
        catch(e){
            message.error("Error Occurred");
        }
        
    }
}

export default new AppointmentService();