import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../App.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAddItemToCart } from "../../../app/CartSlice";
import Npcard from "../Products/Card";
import ProductService from "../../../Redux/Services/ProductService";

const ProductList = ({ currProductList }) => {
  const [cartPopUpOpen, setCartPopUpOpen] = useState();
  const [currCart, setCurrCart] = useState();
  const [currentlySelected, setCurrentlySelected] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddToCart = (product) => {
    dispatch(setAddItemToCart(product));
  };

  const handleClick = () => {
    navigate("/productdetails");
  };

  const handleViewAllClick = () => {
    navigate("/products");
  };

  const handleCartPopUp = () => {
    setCartPopUpOpen(false);
  };

  const handleCartPopUpChange = (number) => {
    setCurrCart(number);
  };

  const fetProductDetails = async (code) => {
    const response = await ProductService.productInvetoryData({ productCode: code });
    console.log(response.data);
    return response.data;
  }

  return (
    <>
      {currProductList?.map((product, idx) => (
        <Npcard
          product={product}
          currCart={currCart}
          productInfo={async () => await fetProductDetails(product.code)}
          handleCartPopUpChange={handleCartPopUpChange}
        />
      ))}
    </>
  );
};

export default ProductList;
