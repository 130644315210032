import React, { useRef, useState } from "react";
import Layouts from "../../Layouts";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import getin from "../../../Assests/images/get in.jpg";
import { Form, Input, InputNumber, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import ContactService from "../../../Redux/Services/ContactService";

function Contact() {
  const formRef = useRef();
  const facebookPageUrl = "https://www.facebook.com/your-facebook-page-url";
  const twitterPageUrl = "https://twitter.com/your-twitter-page-url";
  const instagramPageUrl = "https://www.instagram.com/your-instagram-page-url";
  const linkedinPageUrl = "https://in.linkedin.com/";

  const handleKeyPress = (e) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone, feedback } = formRef.current.getFieldValue();

    if (name === "" || name === undefined || name === null) {
      message.error("Please fill in all fields");
      return;
    }

    if (email === "" || email === undefined || email === null) {
      message.error("Please fill in all the fields");
      return;
    }

    if (phone === "" || phone === undefined || phone === null) {
      message.error("Please fill in all the fields");
      return;
    }

    if (feedback === "" || feedback === undefined || feedback === null) {
      message.error("Please fill in all the fields");
      return;
    }

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;


    let payload = {
      address: "string",
      code: "string",
      createdBy: "string",
      createdDate: constructedTime,
      email,
      mobileNumber: phone,
      modifiedBy: "string",
      modifiedDate: constructedTime,
      status: 1
    }

    try {
      await ContactService.contactUs(payload);
      message.success("Thank You! Our support team will contact you shortly.");
    }
    catch (e) {
      message.error("Error Occurred");
    }
  };


  return (
    <Layouts>
      <div className="bg-[#FBFBFB]">
        <div className="h-auto">
          <div className=" relative">
            <img className="w-full h-52 object-cover" src={getin} alt="" />
            <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:text-[50px] text-xl font-black text-white">
              Get in Touch!
            </p>
          </div>
          <div className="flex items-center md:justify-center">
            <div className="md:flex md:justify-center gap-4 md:p-5 p-5 bg-white shadow-lg md:w-[80%] w-full rounded-md md:mt-5 mb-5">
              <section className="md:w-1/2 flex justify-center">
                <Form ref={formRef} className="w-full">

                  <div className="">
                    <label className="text-lg uppercase text-[#A17A17] font-medium">
                      leave a message here
                    </label>
                    <p className="mt-1">Feel free to drop us a line below</p>
                    <div className="grid md:w-full">
                      <label className="mt-3">
                        Name
                        <span className=" text-red-500 text-md">*</span>
                      </label>
                      <Form.Item name="name">
                        <Input
                          // type="text"
                          className="border border-gray-500 p-2 w-full rounded-md focus:outline-none"
                          placeholder="Enter your Name"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          required
                        />
                      </Form.Item>

                      {/* {errors.name && (
                        <p className="absolute text-red-500">{errors.name}</p>
                      )} */}
                      <label className="mt-2">
                        Email
                        <span className=" text-red-500 text-md">*</span>
                      </label>
                      <Form.Item name="email">
                        <Input
                          // type="email"
                          className="border border-gray-500 w-full p-2 rounded-md focus:outline-none"
                          placeholder="Enter your Email"
                          // name="email"
                          // value={formData.email}
                          // onChange={handleChange}
                          required
                        />

                      </Form.Item>

                      {/* {errors.email && (
                        <p className="absolute text-red-500 ">{errors.email}</p>
                      )}   */}

                      <label className="mt-2">
                        Mobile
                        <span className=" text-red-500 text-md">*</span>
                      </label>
                      <Form.Item name="phone">
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          maxLength={10}
                          // type="email"
                          className="border border-gray-500 w-full p-2 rounded-md focus:outline-none"
                          placeholder="Enter your Mobile No"
                          // name="email"
                          // value={formData.email}
                          // onChange={handleChange}
                          required
                        />

                      </Form.Item>


                      <label className="mt-2">Message
                        <span className=" text-red-500 text-md">*</span>
                      </label>
                      <Form.Item name="feedback">
                        <TextArea
                          autoSize={{ minRows: 5 }}
                          className="w-full border border-gray-500  resize-none p-2 rounded-md"
                          placeholder="Drop message / concern"
                        // type="text"
                        />
                      </Form.Item>

                    </div>
                    <button onClick={handleSubmit} className="border mt-3 bg-[#A17A17] text-white p-2 w-28 rounded-md">
                      Send
                    </button>
                  </div>
                </Form>
              </section>
              <section className="md:w-1/2 flex justify-center">
                <div className="border bg-[#A17A17] md:w-72 md:p-4 p-4 md:h-72 mt-5 items-center text-white rounded-lg">
                  <span className="text-lg">Contact Information</span>
                  <div className="mt-3 leading-5">
                    <p className="flex text-xs gap-3 ">
                      <IoLocationOutline className="mt-1 w-6 h-6" />
                      138, Kutchery RD, Girija Garden, Mylapore, <br />
                      Chennai-600004,
                      <br />
                      TamilNadu
                    </p>
                  </div>
                  <div className="text-sm mt-3">
                    <p className="flex text-xs gap-3">
                      <IoCallOutline className=" w-5 h-5" />
                      +91 044 24984513
                    </p>
                  </div>
                  <div className="text-sm mt-3">
                    <p className="flex text-xs gap-3">
                      <CiMail className="w-5 h-5" />
                      info@venkataramana
                      <br />
                      ayurveda.in
                    </p>
                  </div>
                  <div className="flex mt-4 gap-3 justify-start">
                    <div className="border bg-[#FFF0C0] rounded-3xl h-8 w-8 flex items-center">
                      <a
                        href={instagramPageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BsInstagram className="mx-2 text-[#A17A17]" />
                      </a>
                    </div>
                    <div className="border bg-[#FFF0C0] rounded-3xl h-8 w-8 flex items-center">
                      <a href={facebookPageUrl}>
                        <FaFacebookSquare className="mx-2 text-[#A17A17]" />
                      </a>
                    </div>
                    <div className="border bg-[#FFF0C0] rounded-3xl h-8 w-8 flex items-center">
                      <a
                        href={twitterPageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaXTwitter className="mx-2 text-[#A17A17]" />
                      </a>
                    </div>
                    <div className="border bg-[#FFF0C0] rounded-3xl h-8 w-8 flex items-center">
                      <a
                        href={linkedinPageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="mx-2 text-[#A17A17]" />
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default Contact;