import axios from "axios";

const http = axios.create({
    baseURL: "http://3.128.43.131:8080/ayu",
    headers: {
        "Content-type": "application/json",
    },
});

const fetchAccessToken = async () => {
    try {
        const response = await http.get("/oauth/token?userId=ayuadm%40gmail.com&password=VGVzdEAxMjM%3D");
        const { access_token } = response.data;
        localStorage.setItem("access_token", access_token);
        console.log(access_token)
        return access_token;
    } catch (error) {
        console.error("Error fetching access token:", error);
        throw error;
    }
};

const initializeHttp = async () => {
    try {
        const accessToken = await fetchAccessToken();
        http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } catch (error) {
        console.error("Failed to initialize HTTP client:", error);
    }
};

initializeHttp();

export default http;
