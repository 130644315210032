import React from "react";
import Layouts from "../../Layouts";
import image from '../../../Assests/images/MahatmaGandhi.png';
import image1 from '../../../Assests/images/Venkataramana Iyer.png'
import image2 from '../../../Assests/images/Since1959.png'
import border from '../../../Assests/images/BorderDesign.png';
import Ingredients from '../../../Assests/images/Herbal.png';

const About = () => {
  return (
    <Layouts>
      <div>
        <img src={image} alt="3" className="responsive-image w-full" />
        <div className="mt-0 mb-16 ">
          <div className="md:min-h-[500px] md:flex justify-center items-center backdrop-blur-xl md:py-12 py-4 sm:py-0 ">
            <div>
              <div className="flex flex-col items-center justify-center gap-1 w-full md:h-[210px] h-[120px] bg-[#FFF0C0]">
                <h1 className="text-brown flex font-bold  md:text-[1.75rem]">
                  TRUST IN AYURVEDA MEDICINES IN
                </h1>
                <div className="text-brown flex font-bold  md:text-[1.75rem]">
                  <h1>THE PAST 118 YEARS</h1></div>
                <p className="font-medium md:text-[1.25rem] mt-1">About us</p>
                <img className="mt-2" src={border} alt="border" />
              </div>
              <div className=" md:my-5 my-2 md:p-0 p-4">

                <div className="md:flex items-center justify-end ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="leading-8 text-justify font-normal text-base">Welcome to our cherished world of Ayurveda, where tradition meets innovation to create a timeless legacy. With a history that spans over a century, we are humbled and honored to be entrusted with the responsibility of preserving and propagating the ancient wisdom of Ayurveda.</p>
                    <p className="leading-8 text-justify font-normal text-base">Ventakaramana Ayurveda was established over 118 years ago by our Honourable V.Krinshnaswamy lyer and followed by visionary Ayurvedic practitioners. Our journey began with a humble commitment to heal and nurture through nature's gift. From a small herbal dispensary to a renowned bastion of Ayurvedic excellence, we have traversed a path of growth, knowledge, and unwavering dedication to our mission.</p>
                  </div>
                  <div className="md:w-[50%] ">
                    <img
                      src={Ingredients}
                      alt="ayur img"
                      className="md:w-[500px]  md:h-[450px]  md:ml-16 lg:ml-11"
                    />
                  </div>

                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-1 w-full md:h-[210px] h-[100px] bg-[#FFF0C0]">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  KEY MILESTONES
                </h1>
                <img className="mt-2" src={border} alt="border" />
              </div>
              <div className="md:my-[5rem] md:mx-[5rem]">

                <div className="my-12 md:p-0 p-4 md:mx-[3rem]">
                  <p className="text-justify font-normal text-base">Out founder, Honourable V. Krishnaswamy lyer was driven by a passion to alleviate suffering and promote well-being through the timeless principles of Ayurveda in a small town, amidst nature's bounty Mr lyer laid the foundation of what would become a beacon of Ayurvedic tradition. Our lineage of Ayurvedic practitioners carried forward the sacred knowledge, handed down through generations. Embracing the ancient texts, we continued to deepen our understanding of Ayurveda's holistic approach to health.</p>
                  <div className="float-right">
                    <img
                      src={image1}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px]"
                    />
                  </div>
                  <p className="text-justify font-normal text-base leading-6 py-3">As word spread about the efficacy of our remedies, our reputation greve, attracting patrons from far and wide. With each satisfied soul healed, our commitment to authentic Ayurveda only strengthened.</p>
                  <p className="text-justify font-normal text-base py-3">While staying rooted in tradition, we also embraced scientific research and modern techniques te enhance the effectiveness of our Ayurvedic solutions. Balancing the age old wisdom with contemporary knowledge, we honed our formulations to cater to modem needs.</p>
                  <p className="text-justify font-normal text-base py-3">With the advent of the digital age, we embarked on a journey to share Ayurvedic wisdom with the world. Our online presence allowed us to reach a global audi-ence, spreading the message of natural healing far beyond our physical boundaries.</p>
                  <p className="text-justify font-normal text-base py-3">Over the years, we have earned the trust and respect of countless individuals who have experienced the transformative power of Ayurveda through our products and treatments. Their unwavering support fuels our passion to keep the flame of tradition alive.</p>


                </div>

                <div>

                </div>
              </div>
              <div className="flex flex-col items-center gap-1 w-full md:h-[210px] h-[120px] bg-[#FFF0C0] justify-center">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  OUR COMMITMENT
                </h1>
                <img className="mt-2" src={border} alt="border" />
              </div>
              <div className="">

                <div className=" md:flex relative md:mx-5 md:p-0 p-4 my-5">

                  <div className="md:w-[50%] md:ml-16 flex flex-col gap-4 py-3">
                    <p className="text-justify font-normal text-base leading-6 py-2">Today, as we stand tall on the foundations laid by Hon'ble V. Krishnaswamy lyer, we remain committed to deliver authentic Ayurveda with a touch of innovation.</p>
                    <p className="text-justify font-normal text-base leading-6 py-2">Our experienced team of Ayurvedic experts continues to curate products and treatments that honor the ancient principles while adapting to the evolving needs of modern life. We invite you to be a part of our legacy, to experience the time-honoured wisdom of Ayurveda, and to embark on a journey of health, harmony, and well-being.</p>
                    <p className="text-justify font-normal text-base leading-6 py-2">As we celebrate 118 years of our Ayurvedic heritage, we extend our heartfelt gratitude to every individual who has been a part of our journey, and we look forward to touching many more lives in the years to come.</p>
                    <p className="text-justify font-normal text-base leading-6 py-2 italic text-darkGreen">“Explore our authentic Ayurvedic remedies and embrace the legacy of wellness with us!"</p>
                  </div>
                  <div>
                    <img
                      src={image2}
                      alt="ayur img"
                      className="md:max-w-[500px] md:h-[450px] md:ml-14 "
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default About;