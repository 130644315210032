import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { Breadcrumb, Input } from "antd";
import { TbTruckDelivery } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { setAddItemToCart } from "../../../app/CartSlice";
import uniqid from 'uniqid';
import { useParams } from "react-router-dom";
import ProductService from "../../../Redux/Services/ProductService";
// import productsData from "../../../sampleData/Productdata";



function Productdetail() {
  const [activeTab, setActiveTab] = useState("description");
  const [selectedSize, setSelectedSize] = useState("100gm");
  const [selectedProductPrice, setSelectedProductPrice] = useState();
  const [selectedProductMrp, setSelectedProductMrp] = useState();
  const [currIdx, setCurrIdx] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currImg, setCurrImg] = useState();
  const [currItem, setCurrItem] = useState();
  const [productsData, setProducts] = useState([]);
  const [currProdDetInfo,setCurrProdDetInfo] = useState();
  const [currProduct,setCurrProduct] = useState([]);

  const ProductListData = async () => {
    let payload = {
      status: 1,
      id: id,
    };
    await ProductService.ProductList(payload).then((result) => {
      setCurrItem(result.data[0]);
      let payLoad = {
        status: 1,
        productCode:result.data[0].code
      }
      return ProductService.productInvetoryData(payLoad).then((result)=>{
        setCurrProdDetInfo(result.data);
      })

    });
  };

  const fetProductDetails = async (code) => {
    const response = await ProductService.productInvetoryData({ productCode: code });
    console.log(response.data);
    setCurrProduct(response.data)
    return response.data;
  }

  useEffect(() => {
    if (currItem && currItem.productPackageDetails && currItem.productPackageDetails.length > 0) {
      const defaultSize = currItem.productPackageDetails[0];
      handleSize(defaultSize, 0);
  
      const selectedProduct = currProdDetInfo?.find(item => item.packageCode === defaultSize.packageSize);
  
      if (selectedProduct) {
        setSelectedProductMrp(selectedProduct.unitPrice);
        setSelectedProductPrice(selectedProduct.finalPrice);
      }
    }
  }, [currProdDetInfo, currItem]);
  
  useEffect(() => {
    ProductListData();
    fetProductDetails()
  }, [id])

  useEffect(() => {
    // const item = productsData.filter((item) => item.id === id);
    // setCurrItem(item);
    // console.log('item',item)
    // setSelectedProductMrp(item?.MRP[0]);
    // setSelectedProductPrice(item?.price[0]);
    // setCurrImg(item?.imageName);
  }, [id]);

  const handleAddToCart = () => {
    const itemId = uniqid();
    const itemTobeAdded = { ...currItem, itemId, selectedProductPrice, selectedSize };
    dispatch(setAddItemToCart(itemTobeAdded));
  }

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleSize = (size, idx) => {
    setSelectedSize(size);
    setCurrIdx(idx);
    // let price;
    // let mrp;
    // for (let i = 0; i < currItem?.price.length; i++) {
    //   console.log(currItem.price[idx]);
    //   if (i === idx) {
    //     price = currItem.price[idx];
    //     mrp = currItem.MRP[idx];
    //   }
    // }
    const selected = currProdDetInfo?.filter((item,idx) => item.packageCode === size.packageSize)[0];
    console.log(selected);
    if(selected){
      setSelectedProductMrp(selected.unitPrice);
      setSelectedProductPrice(selected.finalPrice);
    }
    // setSelectedProductPrice(price);
    // setSelectedProductMrp(mrp);
  }

  let imgSrcs = [
    {
      src: currItem?.imageUrl,
      alt: "productImage1",
    },
    {
      src: currItem?.imageUrl,
      alt: "productImage2",
    },
    {
      src: currItem?.imageUrl,
      alt: "productImage3",
    },
    {
      src: currItem?.imageUrl,
      alt: "productImage4",
    },
  ];

  return (
    <Layouts>
      <div className="container mx-auto">
        <div className="bg-textWhite h-full w-full">
          <div className="mt-10">
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: "Home",
                },
                {
                  title: "All",
                },
                {
                  title: currItem?.name,
                  href: "",
                  className: "breadcrumb",
                },
              ]}
            />
          </div>
          <div className="mt-3 w-full p-2">
            <div className="grid md:grid-cols-2 gap-5">
              <div className="flex gap-3">
                <div className="grid gap-1 cursor-pointer">
                  <div className="border border-[#492F00] md:w-24 w-16 md:h-16 flex justify-center items-center">
                    <img src={process.env.REACT_APP_IMAGE_URL + currItem?.imageName} alt="" className="w-20 h-16" />
                  </div>
                  <div className="border md:w-24 w-16 h-16 flex justify-center items-center">
                    <img src={process.env.REACT_APP_IMAGE_URL + currItem?.imageName} alt="" className="w-20 h-16" />
                  </div>
                  <div className="border md:w-24 w-16 h-16 flex justify-center items-center">
                    <img src={process.env.REACT_APP_IMAGE_URL + currItem?.imageName} alt="" className="w-20 h-16" />
                  </div>
                  <div className="border md:w-24 w-16 h-16 flex justify-center items-center">
                    <img src={process.env.REACT_APP_IMAGE_URL + currItem?.imageName} alt="" className="w-20 h-16" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="border border-[#492F00] flex items-center justify-center h-[265px] lg:h-auto">
                    <img src={process.env.REACT_APP_IMAGE_URL + currItem?.imageName} alt="" className="" />
                  </div>
                </div>
              </div>
              <div className="">
                <h2 className="text-xl">{currItem?.name}</h2>
                <div className="flex gap-3 mt-3">
                  <p className="line-through text-[#676767]">INR {selectedProductMrp}</p>
                  <p className="text-[#492F00]">INR {selectedProductPrice}</p>
                </div>
                <p className="mt-3 text-sm">select size</p>
                <div className="flex gap-2 mt-3 w-full">

                  {
                    currItem?.productPackageDetails?.map((size, idx) => (
                      <button
                        className={`border-2 border-popButtoncolour text-[#A17A17]  rounded-[50px] md:w-24 px-2 ${(selectedSize === size && currIdx === idx) ? "text-white bg-[#A17A17]" : (selectedSize === "250gm" && currIdx === idx) ? "text-white bg-[#A17A17]" : (selectedSize === "500gm" && currIdx === idx) ? "text-white bg-[#A17A17]" : ''
                          }`}
                        onClick={() => handleSize(size, idx)}
                      >
                        {size.packageSize}
                      </button>)
                    )
                  }
                </div>
                <div className="flex gap-3 mt-4 items-center">
                  <button className="border rounded-lg h-10 md:w-44 bg-[#A17A17] text-textWhite text-sm w-[50%]" onClick={handleAddToCart}>
                    Add To Cart
                  </button>
                  <button className="border-2 border-popButtoncolour md:w-44 rounded-lg h-10 text-green text-sm w-[50%]">
                    Buy Now
                  </button>
                </div>
                <div className="flex gap-2 items-center mt-3">
                  <TbTruckDelivery className="text-base" />
                  <p className="text-xs uppercase">check delivery time</p>
                </div>
                <div>
                  <div className="flex gap-2 items-center h-[4rem]">
                    <Input
                      className="shadow outline-none rounded-md h-8 w-40"
                      placeholder="Enter pin code"
                      style={{ fontSize: "14px" }}
                    />
                    <button className="border bg-[#A17A17] text-white text-sm rounded-lg w-28 h-8">
                      Check
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row p-2 md:mt-5 justify-center md:gap-8 border-t border-b font-medium bg-[#FFF0C0] border-slate-300 text-center text-[#676767] md:w-full md:scroll-mx-0">
        <button
          onClick={() => handleTabClick("description")}
          className={` text-sm md:text-lg ${activeTab === "description"
            ? "font-bold border-b-2  border-[#EE7214] pb-1"
            : "pb-1"
            }`}
        >
          DESCRIPTION
        </button>
        <button
          onClick={() => handleTabClick("howtouse")}
          className={` text-sm md:text-lg ${activeTab === "howtouse"
            ? "font-bold border-b-2  border-[#EE7214] pb-1"
            : "pb-1"
            }`}
        >
          HOW TO USE
        </button>
        <button
          onClick={() => handleTabClick("ingredients")}
          className={`text-sm md:text-lg ${activeTab === "ingredients"
            ? "font-bold border-b-2  border-[#EE7214] pb-1"
            : "pb-1"
            }`}
        >
          INGREDIENTS
        </button>
        <button
          onClick={() => handleTabClick("benefits")}
          className={`text-sm md:text-lg ${activeTab === "benefits"
            ? "font-bold border-b-2  border-[#EE7214] pb-1"
            : "pb-1"
            }`}
        >
          BENEFITS
        </button>
      </div>
      <div className="container tab-content mt-3 font-sans font-medium mb-3 w-full text-justify">
        {activeTab === "description" && (
          <ul className="list-disc text-base p-4 md:p-0">
            <h3 className="font-semibold text-base mb-4">Description</h3>
            <p className="text-sm leading-7 text-[#404040] ">
             {currItem?.description}
            </p>
            {/* <p className="py-3 text-sm leading-7">
              Indukanta Ghritam, crafted by The Arya Vaidya Pharmacy
              (Coimbatore) Ltd, is an exceptional blend of ingredients
              formulated to provide support in the management of
              gynaecological conditions. The formulation contains Asoka as the
              main ingredient which aids support in conditions like heavy
              bleeding and painful periods dominated by the imbalance of Vata
              and Pitta doshas. It is also beneficial in reducing bleeding in
              piles due to the presence of Vasa and Chandana.
            </p>
            <p className="text-sm leading-7">
              A vast majority of women suffer from muscle cramps, distension
              of the stomach, and other issues related to menstrual period.
              The addition of ingredients like Ginger, Nut Grass, Cumin, etc
              in the tonic aids in improving digestive health and overall
              well-being of the individual.
            </p> */}
          </ul>
        )}
        {activeTab === "howtouse" && (
            currItem?.productToUse.map((item, i) => (
              <ul className="text-sm" key={i}>
                <li className="pb-1 md:p-0 p-4 leading-7">
                  {item.description}
                </li>
              </ul>
            ))
          )}
        {activeTab === "ingredients" && (
          currItem?.productIngredients.map((item, i) => (
            <ul className="text-sm" key={i}>
              <li className="pb-1 md:p-0 p-4 leading-7">
                {item.ingredientName}
              </li>
            </ul>
          ))
        )}
        {activeTab === "benefits" && (
          currItem?.productBenfits.map((item, i) => (
            <ul className="text-sm" key={i}>
              <li className="pb-1 md:p-0 p-4 leading-7">
                {item.benfitDetails}
              </li>
            </ul>
          ))
        )}
      </div>
    </Layouts>
  );
}

export default Productdetail;