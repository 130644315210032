import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { IoIosMore } from "react-icons/io";
import uniqid from "uniqid";
import { useDispatch } from "react-redux";
import { setAddItemToCart } from "../../../app/CartSlice";

const CardComponent = ({ product, productInfo, currCart, handleCartPopUpChange }) => {
  const [cartPopUpOpen, setCartPopUpOpen] = useState(false);
  const [highlightSize, setHighlightSize] = useState(false); // State to highlight size
  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState([]); // Set default size to "100gm"
  const [currIdx, setCurrIdx] = useState(2); // Set default index to 0
  const [selectedProductPrice, setSelectedProductPrice] = useState();
  const [selectedProductMrp, setSelectedProductMrp] = useState();
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [buttonText, setButtonText] = useState("Add To Cart");
  const dispatch = useDispatch();
  const [currProduct,setCurrProduct] = useState([]);

  useEffect(() => {
    getProductDetailInfo();
    // setSelectedProductPrice('');
    // setSelectedProductMrp('');
    // setSelectedSize()
  },[product])

  const getProductDetailInfo = async () => {
    const response = await productInfo();
    setCurrProduct(response);
  }

  const AddToCart = () => {
    const itemTobeAdded = {
      ...product,
      selectedProductPrice,
      selectedSize,
    };
    if (itemTobeAdded.selectedSize) {
      dispatch(setAddItemToCart(itemTobeAdded));
      setButtonText("Adding...");
      setTimeout(() => {
        setButtonText("Add To Cart");
      }, 3000);
    } else {
      message.error("please select size");
    }
  };

  const handleCartPopUp = () => {
    setCartPopUpOpen((prevState) => !prevState);
  };

  const handleClick = () => {
    navigate(`/productdetail/${product.id}`);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // On component mount, update selected product price and MRP based on default size
    // if (item && item.price.length > 0 && item.MRP.length > 0) {
    //   setSelectedProductPrice(item.price[currIdx]);
    //   setSelectedProductMrp(item.MRP[currIdx]);
    // }
  }, [product, currIdx]);

  useEffect(() => {
    if (product && product.productPackageDetails && product.productPackageDetails.length > 0) {
      const defaultSize = product.productPackageDetails[0];
      handleSize(defaultSize, 0);
  
      const selectedProduct = currProduct?.find(item => item.packageCode === defaultSize.packageSize);
  
      if (selectedProduct) {
        setSelectedProductMrp(selectedProduct.unitPrice);
        setSelectedProductPrice(selectedProduct.finalPrice);
      }
    }
  }, [product, currProduct]);
  
  const handleSize = (size, idx) => {
    setSelectedSize(size);
    setCurrIdx(idx);
    const selected = currProduct?.filter((item,idx) => item.packageCode === size.packageSize)[0];
    if(selected){
      setSelectedProductMrp(selected.unitPrice);
      setSelectedProductPrice(selected.finalPrice);
      setSelectedDiscount(selected.discountPercentage)
    }
  };

  const handleAddToCart = () => {
    setHighlightSize(true);
    setCartPopUpOpen(true);
    handleCartPopUpChange(product.id);
  };

  return (
    <div className="p-3 bg-white rounded-md relative ">
      <div className="absolute bg-orange rounded-e-md px-2 top-8 left-0 w-12 font-base text-white">
        {selectedDiscount}%
      </div>
      <div className="w-64 h-64" style={{padding:"20px 20px 20px"}}>
        <img
          alt="Product Image"
          src={process.env.REACT_APP_IMAGE_URL + product?.imageName}
          className="w-full h-full object-contain cursor-pointer"
          onClick={handleClick}
        />
      </div>
      <div className="absolute top-5 mr-2 right-0" onClick={handleClick}>
        <IoIosMore
          style={{
            borderRadius: "50%",
            cursor: "pointer",
            fontSize: "35px",
          }}
        />
      </div>
      <div>
        <p> {product?.name}</p>
      </div>

      <div className="flex flex-wrap text-sm gap-3 my-3">
        {product?.productPackageDetails?.map((size, idx) => (
          <button
            key={idx}
            className={` ${
              selectedSize === size && currIdx === idx
                ? "bg-secondaryColor rounded-xl px-2 border border-secondaryColor h-6"
                : "bg-popUpgreen rounded-xl px-2 border border-secondaryColor h-6"
            }`}
            onClick={() => handleSize(size, idx)}
          >
            {size.packageSize}
          </button>
        ))}
      </div>

      {/* {selectedSize && ( */}
        <div className="flex gap-2 my-2 font-base ">
          <p className="line-through">INR {selectedProductMrp}</p>
          <p>INR {selectedProductPrice}</p>
        </div>
      {/* )} */}
      <div onClick={handleAddToCart} className="flex justify-center ">
        <button
          onClick={AddToCart}
          className="bg-secondaryColor rounded-md h-12 px-3 w-full  text-textWhite font-semibold"
        >
          <span>{buttonText}</span>
        </button>
      </div>
    </div>
  );
};

export default CardComponent;
