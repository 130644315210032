import React, { useState } from "react";
import Layouts from "../../Layouts";
import { IoLocationOutline, IoTrashOutline } from "react-icons/io5";
import CartBackground from "../../../Assests/images/CartBackground.png";
import Counter from '../../../Shared/Counter';
import { useDispatch, useSelector } from "react-redux";
import { setIncreaseItemQTY, setDecreaseItemQTY, selectTotalAmount, setRemoveItemFromCart } from "../../../app/CartSlice";
import { useNavigate } from "react-router-dom";
// import { BsArrowLeft } from "react-icons/bs";
import Address from "../CartPage/Address";
import { Drawer, Input, Modal, Radio } from "antd";
import { TiPlus } from "react-icons/ti";


function CartPage() {
  const state = useSelector(state => state.cart.cartItems);
  const cartTotal = useSelector(state => state.cart.cartTotalAmount);
  const dispatch = useDispatch();
  console.log(cartTotal);
  const deliveryCharge = 20;
  const navigate = useNavigate();
  console.log(state)
  const handleProductsClick = () => {
    navigate('/product');
  }

  const [showAddressModal, setShowAddressModal] = useState(false); // State to control the visibility of the address modal

  const handleClick = (type, id) => {
    console.log("curr ", type, id);
    if (type === 'increment') {
      dispatch(setIncreaseItemQTY(id));
    }
    else if (type === 'decrement') {
      dispatch(setDecreaseItemQTY(id));
    }
  }

  const handleItemDelete = (id) => {
    dispatch(setRemoveItemFromCart(id));
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [selectedValue, setSelectedValue] = useState('');
  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    console.log('Radio changed:', e.target.value);
  };
  return (
    <Layouts>
      <div className="bg-white">
        <div className="relative">
          <img
            src={CartBackground}
            alt="cart"
            className="w-full h-52 mt-0 md:mt-8 object-cover"
          />
          <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl md:text-[50px] font-black text-white">
            My Cart
          </p>
        </div>
        {state.length === 0 ?
          (<div className="flex flex-col gap-4 justify-center items-center h-[50vh]">
            <h1 className="md:text-4xl text-xl font-medium text-productColour">Your Cart is empty</h1>
            <button className="border-2 border-popButtoncolour rounded-md bg-[#A17A17] text-white w-[200px] md:w-[250px] md:h-[60px] h-[40px] font-sans font-medium text-lg mt-5" onClick={handleProductsClick}>
              {/* <BsArrowLeft className="absolute mt-1 size-6" /> */}
              Continue Shopping
            </button>
          </div>
          ) :
          (<div className="container text-pi md:flex justify-between px-6 gap-5 mt-5 mb-20">
            <div className="md:w-[60%]  overflow-hidden">
              {
                state.length !== 0 &&
                (
                  state.map((item, idx) => (
                    <div key={idx} className="justify-between md:mb-6 mb-4 rounded-lg border  drop-shadow-xl bg-white md:p-6 p-4 sm:flex  sm:justify-start">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}${item.imageName}`}
                        alt="product"
                        className=" rounded-lg w-48 mx-auto"
                      />
                      <div className="md:ml-10 flex w-full justify-between ">
                        <div className="mt-2 sm:mt-0">
                          <h2 className="md:text-2xl text-xl font-sans font-medium">{item.name}</h2>
                          <h2 className="mt-2 text-lg text-green-900">{item.selectedSize.packageSize}</h2>
                          <p className="mt-2 text-lg font-sans font-medium">INR {item.selectedProductPrice}</p>
                          <div className="mt-3">
                            <Counter counter={item.cartQuantity} handleClick={handleClick} id={item.id} />
                          </div>
                        </div>
                        <div className="mt-3 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                          <IoTrashOutline className="text-3xl hover:cursor-pointer text-red-700" onClick={() => handleItemDelete(item)} />
                        </div>
                      </div>
                    </div>
                  ))
                )
              }
            </div>
            {/* <SideDrawer visible={showAddressModal} onCancel={() => setShowAddressModal(false)} /> */}
            <div className="md:w-[30%]">
              <div className="border rounded-lg shadow-md bg-[#FFF0C0] p-3">
                <div className="flex gap-1">
                  <IoLocationOutline className="mt-1 text-[20px]" />
                  <h3 className="text-xl font-medium">Delivery Address</h3>
                </div>
                <hr className="my-1 border-b-2 border-gray-500" />
                <div className="mt-2">
                  <p>Balaji</p>
                  <p>8870558276</p>
                  <p>1/37E,Prevaj Consultants Pvt Ltd,Butt Road, St Thomas Mount, Chennai-600016, Tamilnadu</p>
                </div>
                <div className="flex justify-center items-center">
                  <button className="mt-2 border h-12 w-full mx-4 text-xl font-medium border-popButtoncolour bg-[#A17A17] text-white rounded-lg font-sans" onClick={showDrawer}>Change Address</button>
                </div>
              </div>

              <div className="rounded-lg border bg-[#FFF0C0] p-6 mt-8 shadow-md">
                <div className="bg-popUpgreen p-3 flex justify-between items-center">
                  {/* <span className="" >Avail Coupons</span> */}
                  <input
                    placeholder="Avail Coupons" className="p-2 w-full" />
                  <button onClick={showModal} className="border bg-[#A17A17] p-2 rounded-md text-white ">Apply</button>
                </div>
                {/* <Modal title="Apply Coupon Code" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <Input />
                </Modal> */}

                <div className="mb-2">
                  <h2 className="flex justify-center text-xl text-[#404040] font-bold">
                    Cart Total
                  </h2>
                  <div className="mt-3 flex justify-between font-medium">
                    <p className="text-gray-700">Subtotal</p>
                    <p className="text-gray-700">INR {cartTotal}</p>
                  </div>
                </div>
                <div className="flex justify-between font-medium">
                  <p className="text-gray-700">Shipping</p>
                  <p className="text-gray-700">INR {deliveryCharge}</p>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between mb-3">
                  <p className="text-lg font-bold">Total</p>
                  <div className>
                    <p className="mb-1 text-lg font-bold">INR {cartTotal + deliveryCharge}</p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button className="border rounded w-full p-2 border-popButtoncolour bg-[#A17A17] text-white text-xl font-sans font-medium">
                    Proceed To Pay
                  </button>
                </div>
              </div>
            </div>
          </div>)}
        <Drawer title={<span className="uppercase text-white">Select Delivery Address</span>} onClose={onClose} open={open}>
          <div>
            <Address visible={showAddressModal} onCancel={() => setShowAddressModal(false)} />
            <div className="flex gap-2">
              <TiPlus className="mt-1" />
              <label className="uppercas cursor-pointer" onClick={() => setShowAddressModal(true)}>Add New Address</label>
            </div>
            <div className="flex border-gray-600 mt-3">
              <div className="w-[10%]">
                <Radio
                  className="cursor-pointer"
                  value="option1"
                  onChange={handleRadioChange}
                  checked={selectedValue === 'option1'}
                >
                </Radio>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-16">
                  <p className="text-base font-medium text-gray-700">Balaji</p>
                  <p className="rounded-full border w-16 text-center bg-popButtoncolour text-white">Home</p>
                </div>
                <p className="text-base font-medium text-gray-700">8870558276</p>
                <p className="text-sm mt-1 text-gray-700">1/37E, Prevaj Consultants Pvt Ltd, Butt Road, St Thomas Mount, Chennai-600016, Tamilnadu</p>


                {selectedValue === 'option1' && (
                  <div className="flex w-full mt-2">
                    <button className="border p-2 w-full rounded-md bg-[#A17A17] text-white font-medium">Deliver to this Address</button>
                  </div>
                )}
              </div>
              <div>Edit</div>
            </div>
            <div className="flex border-gray-600 mt-3">
              <div className="w-[10%]">
                <Radio
                  className="cursor-pointer"
                  value="option"
                  onChange={handleRadioChange}
                  checked={selectedValue === 'option'}
                >
                </Radio>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-16">
                  <p className="text-base font-medium text-gray-700">Balaji</p>
                  <p className="rounded-full border w-16 text-center bg-popButtoncolour text-white">Office</p>
                </div>            <p className="text-base font-medium text-gray-700">8870558276</p>
                <p className="text-sm mt-1 text-gray-700">1/37E, Prevaj Consultants Pvt Ltd, Butt Road, St Thomas Mount, Chennai-600016, Tamilnadu</p>
                {selectedValue === 'option' && (
                  <div className="flex w-full mt-2">
                    <button className="border p-2 w-full rounded-md bg-[#A17A17] text-white font-medium">Deliver to this Address</button>
                  </div>
                )}
              </div>
              <div>Edit</div>
            </div>
          </div>
        </Drawer>
      </div>
    </Layouts>
  );
}

export default CartPage;













