import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  cartState: false,
  cartItems: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [], 
  cartTotalAmount: localStorage.getItem('totalAmount')?JSON.parse(localStorage.getItem('totalAmount')):0,
  cartTotalQantity: 0,
};

const CartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    setOpenCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },
    setCloseCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },
    setAddItemToCart: (state, action) => {
      console.log(action.payload);
      const { id, name, imageUrl, selectedSize, selectedProductPrice } = action.payload;
    
      const itemIndex = state.cartItems.findIndex((item) => item.id === id);
    
      if (itemIndex >= 0) {
        if (state.cartItems[itemIndex].selectedSize !== selectedSize) {
          const temp = [...state.cartItems, { ...action.payload, cartQuantity: 1 }];
          state.cartItems = temp;
          toast.success(`${name} added to Cart`);
        } else {
          state.cartItems[itemIndex].cartQuantity += 1;
          state.cartTotalQantity += 1;
          toast.success('Item QTY Increased');
        }
      } else {
        const temp = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(temp);
        state.cartTotalQantity += 1;
        toast.success(`${name} added to Cart`);
      }
      
      state.cartTotalAmount += selectedProductPrice;
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      localStorage.setItem('totalAmount', JSON.stringify(state.cartTotalAmount));
    },
    
    

    setRemoveItemFromCart: (state, action) => {
      const itemsAfterRemoval = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
 
      state.cartItems = itemsAfterRemoval;
      state.cartTotalQantity = itemsAfterRemoval.length
      state.cartTotalAmount -= (action.payload.selectedProductPrice * action.payload.cartQuantity);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      localStorage.setItem('totalAmount',JSON.stringify(state.cartTotalAmount));

      toast.success(`${action.payload.name} Removed From Cart`);
    },

    setIncreaseItemQTY: (state, action) => {
      console.log("action payload at increase : ",action.payload);
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload
      );
      
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;
        state.cartTotalAmount += state.cartItems[itemIndex].selectedProductPrice;
        state.cartTotalQantity = state.cartItems.length;
        toast.success('Item QTY Increased');
      }
      console.log(state.cartTotalAmount);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      localStorage.setItem('totalAmount',JSON.stringify(state.cartTotalAmount));
    },

    setDecreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        state.cartTotalQantity = state.cartItems.length;
        state.cartTotalAmount -= state.cartItems[itemIndex].selectedProductPrice;
        toast.success('Item QTY Decreased');
      }
      console.log(state.cartTotalAmount);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      localStorage.setItem('totalAmount',JSON.stringify(state.cartTotalAmount));
    },

    setClearCartItems: (state, action) => {
      state.cartItems = [];
      state.cartTotalQantity = 0;
      toast.success('Cart Cleared');
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    setGetTotals: (state, action) => {
      let { totalAmount, totalQTY } = state.cartItems.reduce((cartTotal, cartItem)=> {
        const { price, cartQuantity } = cartItem;
        const totalPrice = price * cartQuantity;

        cartTotal.totalAmount += totalPrice;
        cartTotal.totalQTY += cartQuantity;

        return cartTotal;
      }, {
        totalAmount: 0,
        totalQTY: 0,
      });

      state.cartTotalAmount = totalAmount;
      state.cartTotalQantity = totalQTY;
    },
  },
});

export const {
  setOpenCart,
  setCloseCart,
  setAddItemToCart,
  setRemoveItemFromCart,
  setIncreaseItemQTY,
  setDecreaseItemQTY,
  setClearCartItems,
  setGetTotals
} = CartSlice.actions;

export const selectCartState = (state) => state.cart.cartState;
export const selectCartItems = (state) => state.cart.cartItems;

export const selectTotalAmount = (state) => state.cartTotalAmount;
export const selectTotalQTY = (state) => {
  console.log(state.cart.cartTotalQantity)
  return state.cart.cartTotalQantity

};

export default CartSlice.reducer;