import http from "./http_common"

class ProductService {
    queryString(payload) {
        return Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&");
      }

    ProductList(payload) {
        var queryString = this.queryString(payload)
        return http.get(`/productMaster/list/filter?${queryString}`);
    }

    productInvetoryData(payload){
        var queryString = this.queryString(payload)
        return http.get(`/productQty/list/filter?${queryString}`);
    }
}

export default new ProductService();