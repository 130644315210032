import React, { useState } from 'react'
import Layouts from '../Layouts'
import { Radio } from 'antd';

const Myprofile = () => {

    const [value, setValue] = useState(0);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Layouts>
            <div className='flex justify-center'>
                <div className='mx-5 my-5 flex flex-col gap-4  bg-slate-50 w-[50%] p-5 shadow-lg rounded-lg justify-center'>
                    <div className='flex justify-between bg-secondaryColor p-3 items-center rounded-md'>
                        <h1 className='text-3xl text-primaryColor font-medium  '>My Profile</h1>
                        <button className='p-[10px] rounded-md text-white border bg-secondaryColor'>Edit Details</button>
                    </div>
                    <div className='flex gap-5'>
                        <div className='flex flex-col gap-3'>
                            <label className='text-xl'>First Name</label>
                            <input
                                type='text'
                                className='border p-2 rounded-md' />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className='text-xl'>Last Name</label>
                            <input
                                type='text'
                                className='border p-2  rounded-md '
                            />
                        </div>
                    </div>
                    <div className='flex flex-col w-[50%] gap-3'>

                        <label className='text-xl'>Your Gender</label>
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio className='text-base' value={1}>Male</Radio>
                            <Radio className='text-base' value={2}>Female</Radio>

                        </Radio.Group>

                    </div>
                    <div className='flex flex-col w-[50%] gap-3 '>

                        <label className='text-xl'>Email Address</label>
                        <input
                            className='border p-2  rounded-md'
                        />

                    </div>

                    <div className='flex flex-col w-[50%] gap-3'>

                        <label className='text-xl'>Mobile Number</label>
                        <input
                            className='border p-2 rounded-md '
                        />

                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default Myprofile
