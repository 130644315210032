import React from "react";
import { Pagination } from "antd";

function PaginationComponent({ handlePagination, Size, itemsPerPage }) {
  console.log(Size);

  return (
    <div className="flex justify-center items-center mt-5 ">
      <Pagination
       
        defaultCurrent={1}
        total={Size}
        pageSize={itemsPerPage} // Set the total number of items here
        onChange={handlePagination}
        className="ant-pagination ant-table-pagination mb-5"
      />
    </div>
  );
}

export default PaginationComponent;
