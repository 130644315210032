import { message } from "antd";
import http from "./http_common";

class ContactService{
    contactUs(payload){
        try{
            http.post('/contactForm/',payload);
        }
        catch(e){
            message.error("Error Occurred");
        }
    }

   async addAddress(payload){
        try{
            http.post('/userDeliveryAddress/',payload);
        }
        catch(e){
            message.error("Error Occurred");
        }
    }

}

export default new ContactService();