import React, { useState } from 'react'
import Layouts from '../Layouts'
import Address from '../Pages/CartPage/Address';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { Dropdown, Radio } from 'antd';

const MyAddress = () => {
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleMenuClick = (e, address) => {
        if (e.key === '1') {
            setSelectedAddress(address);
            setShowAddressModal(true);
            setOpen(false);
        }
    };

    // const handleMenuClick = (e) => {
    //     if (e.key === '2') {
    //         setOpen(false);
    //     }
    // };
    // const handleMenuClick1 = (e) => {
    //     if (e.key === '2') {
    //         setOpen(false);
    //     }
    // };

    const handleMenuClick1 = (e, address) => {
        if (e.key === '1') {
            setSelectedAddress(address);
            setShowAddressModal(true);
            setOpen1(false);
        }
    };

    
    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };
    const handleOpenChange1 = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen1(nextOpen);
        }
    };

    const items = [
        {
            label: 'Edit',
            key: '1',
        },
        {
            label: 'Delete',
            key: '2',
        },


    ];

    const handleRadioChange = (e) => {
           setSelectedValue(e.target.value);
        }
  


        const [selectedValue, setSelectedValue] = useState('');
    return (
        <Layouts>
            <div className='flex justify-center'>
                <div className='mx-5 my-5 bg-slate-50 w-[50%]   p-4 shadow-lg rounded-lg '>
                    <div className=' flex justify-between items-center bg-popButtoncolour p-3'>
                        <h1 className='text-3xl font-medium text-primaryColor'>My Address</h1>

                        <Address visible={showAddressModal} onCancel={() => setShowAddressModal(false)} />
                        <button className='border bg-popButtoncolour p-3 font-medium text-white rounded-md ' onClick={() => setShowAddressModal(true)} >
                            ADD A NEW ADDRESS
                        </button>
                    </div>
                    {/* <div className='p-3'>
                    No addresses were saved yet
                </div> */}
                    <div className='border p-3 mt-4  '>
                        <div className='flex justify-between'>
                            <div>
                                {/* <Radio
                                    value="option1"
                                    onChange={handleRadioChange}
                                    checked={selectedValue === 'option1'}
                                /> */}
                            </div>
                            <div className='flex justify-between w-full '>
                                <div className='flex justify-between w-full'>

                                    <div className='flex justify-between w-[20%]'>
                                        <span className='font-semibold'>Hari haran </span>
                                        <div className="text-primaryColor bg-secondaryColor  px-3 py-1.5 rounded-full text-xs font-semibold cursor-pointer">
                                            Home
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <Dropdown
                                    menu={{
                                        items, onClick: (e) => handleMenuClick(e, 'address1'),
                                        // onClick: handleMenuClick, 
                                    }}
                                    onOpenChange={handleOpenChange}
                                    open={open}
                                >

                                    <PiDotsThreeOutlineVerticalFill
                                        style={{ cursor: "pointer", fontSize: "25px", }}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div className=''>
                            <p>
                                Address:
                                <span className='font-light'> 29, south street, mount, chennai-600001</span>
                            </p>
                            <div>+91 8072923994</div>
                            {selectedValue === 'option1' && (
                            <button className='p-2 rounded-md bg-buttonGreen text-white border'> Deliver to this Address </button>
                            )}
                            </div>
                    </div>

                    <div className='border p-3 mt-4  '>
                        <div className='flex justify-between'>
                            <div>
                                {/* <Radio
                                    value="option2"
                                     onChange={handleRadioChange}
                                     checked={selectedValue === 'option2'}
                                /> */}
                            </div>
                            <div className='flex justify-between w-full '>
                                <div className='flex justify-between w-full'>

                                    <div className='flex justify-between w-[20%]'>
                                        <span className='font-semibold'>Hari haran </span>
                                        <div className="text-primaryColor bg-secondaryColor  px-3 py-1.5 rounded-full text-xs font-semibold cursor-pointer">
                                            Office
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <Dropdown
                                    menu={{ items, onClick: handleMenuClick1, }}
                                    onOpenChange={handleOpenChange1}
                                    open={open1}
                                >

                                    <PiDotsThreeOutlineVerticalFill
                                        style={{ cursor: "pointer", fontSize: "25px", }}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div className=''>
                            <p>
                                Address:
                                <span className='font-light'> 29, south street, mount, chennai-600001</span>
                            </p>
                            <div>+91 8072923994</div>
                            {/* {selectedValue === 'option2' && (
                            <button className='p-2 rounded-md bg-buttonGreen text-white border'> Deliver to this Address </button>
                        )} */}
                        </div>
                    </div>

                </div>
            </div>
        </Layouts>
    )
}

export default MyAddress
