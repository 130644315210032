import React from 'react';
import { useNavigate } from 'react-router-dom';
import HerbalLeaves from "../../../Assests/HerbImgList/HerbLeaves.svg"
import BannerBottom from "../../../Assests/HerbImgList/BannerBottom.svg"
import lehyas from "../../../Assests/HerbImgList/HerbalLehyas.svg"
import tablets from "../../../Assests/HerbImgList/HerbalTablets.svg"
import churnas from "../../../Assests/HerbImgList/Churanams.svg"




const ProductCategories = ({ currType, handleProductCategories }) => {


    const navigate = useNavigate();



    const handleViewAllClick = (event) => {
        event.preventDefault();
        navigate('/product');
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
  
    }
    return (
        <>
        
            <section className="bg-[#9D7201] w-full">
                <div className="container mx-auto  py-10 ">
                    <div className="text-center">
                        <h1 className="md:text-3xl text-xl font-base text-textWhite mb-4">OUR PRODUCTS</h1>
                        <h3 className="md:text-2xl text-xl font-base text-primaryColor font-medium mb-4">The herbal choice is a healthy choice</h3>
                    </div>
                    <div className="md:grid  grid-cols-5 gap-1">
                        <div className="flex flex-col gap-3 justify-center items-center " >
                            <img src={HerbalLeaves} alt="0" onClick={() => handleProductCategories('Kashayam')} />
                            <p className={`text-center  font-base ${currType === 'Kashayam' ? 'text-textWhite text-lg font-bold' : ''}`}>Kashayam</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center" >
                            <img  src={lehyas} alt="1" onClick={() => handleProductCategories('Churnams')} />
                            <p className={`text-center  font-base ${currType === 'Churnams' ? 'text-textWhite text-lg font-bold' : ''}`}>Churnams</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center" >
                            <img src={tablets} alt="2" onClick={() => handleProductCategories('Tailam')} />
                            <p className={`text-center  font-base ${currType === 'Tailam' ? 'text-textWhite text-lg font-bold' : ''}`}>Tailam</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center" >
                            <img src={churnas} alt="4" onClick={() => handleProductCategories('Ghrithms')} />
                            <p className={`text-center  font-base ${currType === 'Ghrithms' ? 'text-textWhite text-lg font-bold' : ''}`}>Ghrithms</p>
                        </div>

                        <div className="flex flex-col gap-3 justify-center items-center" >
                            <img src={lehyas} alt="3" onClick={() => handleProductCategories('Lehams')} />
                            <p className={`text-center  font-base ${currType === 'Lehams' ? 'text-textWhite text-lg font-bold' : ''}`}>Lehams</p>
                        </div>
                    </div>

                </div>
                <div className="relative bg-[#9D7201] md:h-[200px] h-[100px] flex justify-center items-center">
                    <div className="absolute top-0 w-full h-[101%]">
                        <img src={BannerBottom} alt="/" className="w-full h-full object-cover object-top" />
                    </div>
                    <div className="absolute bottom-0 ">
                        <button onClick={handleViewAllClick} className="h-12 bg-secondaryColor w-28 rounded-2xl text-textWhite font-base">View All</button>
                    </div>
                </div>

            </section>
        </>
    );

}

export default ProductCategories;




