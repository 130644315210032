function Counter({counter,handleClick,id}) {
    
  return (
    <div className="border w-[6rem]">
    <span className='w-[6rem] h-[2rem] flex justify-center items-center bg-backgroundGray border border-borderColor'>
        <button className='w-[2rem] rounded-lg bg-backgroundGray' onClick={() => handleClick('decrement',id)}>-</button>
        <p className='bg-white w-[2rem] h-[inherit] pt-1 pl-3'>{counter}</p>
        <button className='w-[2rem] rounded-lg bg-backgroundGray' onClick={() => handleClick('increment',id)}>+</button>
    </span>
    </div>
  )
}

export default Counter;