import React from 'react'
import Layouts from '../Layouts'
import product from "../../Assests/productImages/product_1.png"
import product1 from "../../Assests/productImages/product_2.png"
import product2 from "../../Assests/productImages/product_3.png"
import { GoDotFill } from "react-icons/go";
import { MdStarRate } from "react-icons/md";
import { Popover, Steps } from 'antd';



const customDot = (dot, { status }) => (
    <span
        style={{
            backgroundColor: status === 'process' ? '#1890ff' : '#e8e8e8',
            borderColor: status === 'process' ? '#1890ff' : '#e8e8e8',
        }}
        className="custom-dot"
    >
        {dot}
    </span>
);


function MyOrder() {
    return (
        <div className='bg-slate-200'>
            <Layouts>

                <div className='container mt-3 mb-3'>
                    <div className='flex gap-1 justify-center'>
                        <input type="text" className='w-1/2 border-2 rounded-md p-2 autofill:none' />
                        <button className='border rounded-md text-white bg-productColour p-2'>Search Orders</button>
                    </div>
                    <div className='w-full h-40 mt-3 shadow-lg rounded-md bg-white'>
                        <div className='flex justify-around items-center p-3'>
                            <div className='flex gap-3'>
                                <img src={product} alt="" className='w-20 items-center border rounded-md' />
                                <div className=''>
                                    <p className='text-base'>INDUKANTA GHRITAM</p>
                                    <p className='text-sm mt-1'>500gm</p>
                                    <p className='text-sm mt-1'>Quantity:<span> 3</span></p>
                                    <p className='text-sm mt-1'>INR 599</p>
                                </div>
                            </div>
                            <div>
                                <Steps
                                    current={2}
                                    progressDot={customDot}
                                    items={[
                                        {
                                            title: <span style={{ fontSize: '15px' }}>Order Confirmed</span>,
                                            description: <span style={{ fontSize: '10px' }}>Fri, 10th May</span>,
                                        },
                                        {
                                            title: <span style={{ fontSize: '15px' }}>Shipped</span>,
                                            description: <span style={{ fontSize: '10px' }}>Sun, 12th May</span>,
                                        },
                                        {
                                            title: <span style={{ fontSize: '15px' }}>Out for delivery</span>,
                                            description: <span style={{ fontSize: '10px' }}>Mon, 13th May</span>,
                                        },
                                        {
                                            title: <span style={{ fontSize: '15px' }}>Delivery</span>,
                                            description: <span style={{ fontSize: '10px' }}>Expected By Wed, 15th May</span>,
                                        },
                                    ]}
                                    style={{ width: '60%' }}
                                />
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <span className='cursor-pointer text-blue-500 p-1 mb-3 mr-2'>
                                Cancel Order
                            </span>

                        </div>
                    </div>
                    <div className='w-full h-40 mt-3 shadow-lg rounded-md bg-white'>
                        <div className='flex justify-around items-center p-3'>
                            <div className='flex gap-3'>
                                <img src={product} alt="" className='w-20 items-center border rounded-md' />
                                <div className='mt-3'>
                                    <p className='text-base'>INDUKANTA GHRITAM</p>
                                    <p className='text-sm mt-1'>500gm</p>
                                    <p className='text-sm mt-2'>Quantity:<span> 3</span></p>
                                </div>
                            </div>
                            <div>
                                <p className='text-base'>INR 599</p>
                            </div>
                            <div>
                                <div className='flex gap-2'>
                                    <GoDotFill className='mt-1 text-green' />
                                    <p className='text-base font-semibold'>Delivered on May 07, 2024</p>
                                </div>
                                <p className='text-sm mx-4 mt-1'>Your item has been delivered</p>
                                <div className='flex mt-2 gap-2 text-blue-500 cursor-pointer'>
                                    <MdStarRate className='mt-1' />
                                    <p>Rate & Review Product</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-40 mt-3 shadow-lg rounded-md bg-white'>
                        <div className='flex justify-around items-center p-3'>
                            <div className='flex gap-3'>
                                <img src={product1} alt="" className='w-20 items-center border rounded-md' />
                                <div className='mt-3'>
                                    <p className='text-base'>INDUKANTA GHRITAM</p>
                                    <p className='text-sm mt-1'>500gm</p>
                                    <p className='text-sm mt-2'>Quantity:<span> 1</span></p>
                                </div>
                            </div>
                            <div>
                                <p className='text-base'>INR 799</p>
                            </div>
                            <div>
                                <div className='flex gap-2'>
                                    <GoDotFill className='mt-1 text-green' />
                                    <p className='text-base font-semibold'>Delivered on June 07, 2024</p>
                                </div>
                                <p className='text-sm mx-4 mt-1'>Your item has been delivered</p>
                                <div className='flex mt-2 gap-2 text-blue-500 cursor-pointer'>
                                    <MdStarRate className='mt-1' />
                                    <p>Rate & Review Product</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-40 mt-3 shadow-lg rounded-md bg-white'>
                        <div className='flex justify-around items-center p-3'>
                            <div className='flex gap-3'>
                                <img src={product2} alt="" className='w-20 items-center border rounded-md' />
                                <div className='mt-3'>
                                    <p className='text-base'>INDUKANTA GHRITAM</p>
                                    <p className='text-sm mt-1'>500gm</p>
                                    <p className='text-sm mt-2'>Quantity:<span> 2</span></p>
                                </div>
                            </div>
                            <div>
                                <p className='text-base'>INR 899</p>
                            </div>
                            <div>
                                <div className='flex gap-2'>
                                    <GoDotFill className='mt-1 text-green' />
                                    <p className='text-base font-semibold'>Delivered on July 07, 2024</p>
                                </div>
                                <p className='text-sm mx-4 mt-1'>Your item has been delivered</p>
                                <div className='flex mt-2 gap-2 text-blue-500 cursor-pointer'>
                                    <MdStarRate className='mt-1' />
                                    <p>Rate & Review Product</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layouts>
        </div>
    )
}

export default MyOrder



