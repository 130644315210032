import React, { useRef } from 'react';
import { Modal, Button, Input, Select, Form, Row, Col, Divider, message } from 'antd';
import ContactService from '../../../Redux/Services/ContactService';

const { Option } = Select;

const Address = ({ visible, onCancel }) => {
  const formRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload;
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const { name, number, email, addressone, addresstwo, landmark, addresstype, city, state, country, pincode } = formRef.current.getFieldValue();
    if (name === "undefined" || number === "undefined" || email === "undefined" || addressone === "undefined" || addresstwo === "undefined" || landmark === "undefined"
      || addresstype === "undefined" || city === "undefined" || state === "undefined" || country === "undefined" || pincode === "undefined") {
      message.error("Please fill in all fields");
      return;
    }
    payload = {
      address1: addressone,
      address2: addresstwo,
      addressType: addresstype,
      city: city,
      country,
      createdBy: "string",
      createdDate:constructedTime,
      email,
      landmark,
      mobileNumber: number,
      modifiedBy: "string",
      modifiedDate: constructedTime,
      name,
      pincode,
      sno: 0,
      state,
      status: 1,
      userId: "string"
    }
    await ContactService.addAddress(payload);
  }

  return (
    <Modal
      title={<span className='text-2xl'>Billing Address</span>}
      visible={visible}
      onCancel={onCancel}
      width={600}
      footer={[]}
    >
      <Divider />
      <Form
        ref={formRef}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label={<span className='text-base'>Name<span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your first name' }]}
            >
              <Input placeholder="name" className='h-10' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="number"
              label={<span className='text-base'>Mobile No <span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your mobile no' }]}

            >
              <Input placeholder="Enter your Number" className='h-10' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label={<span className='text-base'>Email <span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your Email' }]}

            >
              <Input placeholder="Email" className='h-10' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="addressone"
              label={<span className='text-base'>House No/Building name <span className='text-red-500'></span></span>}
              rules={[{ message: '' }]}

            >
              <Input placeholder="" className='h-10' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="addresstwo"
              label={<span className='text-base'>Road name/Area/Colony <span className='text-red-500'></span></span>}
              rules={[{ message: '' }]}

            >
              <Input placeholder="" className='h-10' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="landmark"
              label={<span className='text-base'>Landmark<span className='text-red-500'></span></span>}
            >
              <Input placeholder="Apartment or suite" className='h-10' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="addresstype"
              label={<span className='text-base'>Home/Office<span className='text-red-500'>*</span></span>}
              rules={[{ message: '' }]}

            >
              <Select placeholder="Select a home or office" className='h-10'>
                <Option value="home">Home</Option>
                <Option value="office">Office</Option>
              </Select>
            </Form.Item>


          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="city"
              label={<span className='text-base'>City<span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your city' }]}

            >
              <Select placeholder="Select a city" className='h-10'>
                <Option value="Chennai">Chennai</Option>
              </Select>
            </Form.Item>

          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="state"
              label={<span className='text-base'>State<span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your state' }]}

            >
              <Select placeholder="Select a state" className='h-10'>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>

            <Form.Item
              name="country"
              label={<span className='text-base'>Country<span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your country' }]}

            >
              <Select placeholder="Select a country" className='h-10'>
                <Option value="India">India</Option>
                {/* Add more countries as options */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>

            <Form.Item
              name="pincode"
              label={<span className='text-base'>Pin Code<span className='text-red-500'>*</span></span>}
              rules={[{ message: 'Please enter your pin code' }]}

            >
              <Input placeholder="" className='h-10' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className='flex items-center justify-end gap-2'>
        <button className='w-28 rounded-lg p-2 bg-[#A17A17] text-white border-none' onClick={onCancel}>
          Cancel
        </button>
        <button className='w-28 rounded-lg p-2 bg-[#A17A17] text-white border-none' onClick={handleSubmit}>
          Save
        </button>
      </div>

    </Modal>
  );
};

export default Address;