import React, { useState, useRef, useEffect } from "react";

function Sort({ handleDropdownToggle, handleSort, handlePriceChange, range }) {
  const [items, setItems] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleDropdownToggle();
    }
  };

  const sortOptions = [
    { id: 1, key: "alphabeticalAZ", label: "Alphabetical A to Z" },
    { id: 2, key: "alphabeticalZA", label: "Alphabetical Z to A" },
    { id: 3, key: "priceLowToHigh", label: "Price Low to High" },
    { id: 4, key: "priceHighToLow", label: "Price High to Low" },
  ];

  return (
    <div>
      <div ref={dropdownRef} className="absolute top-[3rem] md:left-[25rem] left-28 z-[9] bg-white border border-gray-200 rounded-lg shadow-sm mt-1 py-2 px-4">
        {sortOptions.map((option) => (
          <div
            key={option.key}
            className="cursor-pointer hover:text-secondaryColor py-1"
            onClick={() => {
              handleSort(option);
              handleDropdownToggle();
            }}
          >
            {option.label}
          </div>
        ))}
        <div className="flex flex-col">
          <label>Price Range :</label>
          <div>
          <input
            type="range"
            id="myRange"
            name="myRange"
            min="0"
            max="1000"
            step="10"
            value={range}
            onChange={handlePriceChange}
          />
          <div className="flex justify-between">
            <span>0</span><span>{range}</span><span>1000</span>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sort;