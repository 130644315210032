import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function Dheader() {
  const sentences = [
    '10% Discount on orders above Rs.599/-',
    'Free shipping above Rs.799/-*T&C Apply  ',
  ];

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const handleLeftArrowClick = () => {
    const newIndex = (currentSentenceIndex - 1 + sentences.length) % sentences.length;
    setCurrentSentenceIndex(newIndex);
  };

  const handleRightArrowClick = () => {
    const newIndex = (currentSentenceIndex + 1) % sentences.length;
    setCurrentSentenceIndex(newIndex);
  };

  return (
    <div className=''>
      <div className='h-10 flex justify-center items-center bg-[#4C3000] text-white  gap-5'>
        <LeftOutlined
          className=' ' onClick={handleLeftArrowClick} />
        <div className=' md:text-md text-center text-sm '>
          {sentences[currentSentenceIndex]}
        </div>
        <RightOutlined className=' ' onClick={handleRightArrowClick} />
      </div>
    </div>
  );
}

export default Dheader;
