import React, { useState, useEffect, Fragment } from "react";
import {
  AudioOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assests/images/Logo1.png";
import logo1 from "../Assests/images/footlogo.png";
import Headrerimage from "../Assests/images/Header.png";
import iyer from "../Assests/images/Hiyer.png";
import PopUp from "./PopUp";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectTotalQTY, setOpenCart } from "../app/CartSlice";
import user from "../Assests/images/User.svg";
import cart from "../Assests/images/Cart.svg";
import { Button, Drawer, Dropdown, Input, Select, Space } from "antd";
import { MdShoppingCartCheckout } from "react-icons/md";
import ProductList from "../Components/Pages/Home/ProductList";
import ProductService from '../Redux/Services/ProductService';
import { Modal } from "antd";
// import Login from "./Login/Login";

const Header = () => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const state = useSelector((state) => state);
  const cartQuantity = useSelector(selectTotalQTY);
  console.log("printing cart quantity", cartQuantity);
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [productSearch, setProductSearch] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const isCartOpen = useSelector((state) => state.cart.cartState);
  console.log("iscart", isCartOpen);
  const onCartToggle = () => {
    dispatch(
      setOpenCart({
        cartState: !isCartOpen,
      })
    );
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setActiveLink(path === "" ? "home" : path);
  }, [location.pathname]);

  const handleSearchClick = () => {
    setIsSearchOpen(true);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  const handleModal = () => {
    console.log("button clicked");
    setIsPopUpOpen((prevState) => !prevState);
  };

  const handleCartPage = () => {
    navigate("/cart");
  };

  const handlehomeClick = () => {
    navigate("/");
  };
  const handleMyProfile = () => {
    navigate("/myprofile");
  };
  const handleMyOrder = () => {
    navigate("/myorders");
  };
  const handleMyAddress = () => {
    navigate("/myaddress");
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };

  const handleClick = () => {
    setIsPopUpOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!isSearchOpen) {
        const offset = window.scrollY;
        setIsHeaderFixed(offset > 0);
      }
    };

    if (!isSearchOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isSearchOpen]);

  const handleActiveLink = (link) => {
    localStorage.setItem("active", link);
  };

  const { Search } = Input;
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1677ff",
      }}
    />
  );
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const [openProfile, setOpenProfile] = useState(false);
  const handleMenuClick = (e) => {
    setOpenProfile(false);
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpenProfile(nextOpen);
    }
  };

  const items = [
    {
      label: <p onClick={handleMyProfile}>My Profile</p>,
      key: "1",
    },
    {
      label: <p onClick={handleMyOrder}>My Orders</p>,
      key: "2",
    },
    {
      label: <p onClick={handleMyAddress}>My Address</p>,
      key: "3",
    },
    {
      label: <p onClick={handleChangePassword}>Change Password</p>,
      key: "4",
    },
    {
      label: <p>Logout</p>,
      key: "5",
    },
  ];

  const handleSearch = (value) => {
    navigate(`/productdetail/${value}`);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const onSearchp = (value) => {
    let payload = {
      name: value,
    }
    ProductService.ProductList(payload).then((res) => {
      setProductSearch(res.data);
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenforgot, setIsModalOpenforgot] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal1 = () => {
    setIsModalOpenforgot(true);
  };
  const showModal2 = () => {
    setIsSignupModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsSignupModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpenforgot(false);
  };
  const handleCancel2 = () => {
    setIsSignupModalOpen(false);
  };
  const toggleForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  };

  const toggleLoginModal = () => {
    setIsLoginModal(!isLoginModal);
  };

  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [visible1, setVisible1] = useState(false);
  const [password1, setPassword1] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleNextClick = () => {
    setShowPasswordFields(true);
  };

  const handleToggleVisibility = () => {
    setVisible(!visible);
  };
  const handleToggleVisibility1 = () => {
    setVisible1(!visible1);
  };

  return (
    <>
      <div className={`w-full ${isHeaderFixed ? "top-0" : ""}`}>
        <div className="" style={{ backgroundImage: `url(${Headrerimage})`}}>
          <div className="container w-full  flex justify-between items-center py-2 h-[80px] md:h-auto">
            <div className="w-[60%]  flex justify-start items-center gap-4">
              <div className="md:hidden block text-white" onClick={showDrawer}>
                <MenuOutlined />
              </div>
              <img
                onClick={handlehomeClick}
                className=" md:w-[50%] cursor-pointer"
                src={logo}
                alt="1"
              />
            </div>

            <div className="w-[29%]   flex justify-center items-center ">
              <img className="w-[90px] md:block hidden " src={iyer} alt="1" />
            </div>
            <div className="md:hidden block">
              <div className="w-[40%] flex items-center gap-2 justify-center">
                <div onClick={handleSearchClick}>
                  <SearchOutlined className="text-xl text-[#FAEDD9] cursor-pointer" />
                </div>
                <div onClick={handleCartPage} className="relative">
                  {/* <img
                    className="w-[100px] cursor-pointer"
                    src={cart}
                    alt="Cart"
                  /> */}
                  <MdShoppingCartCheckout className="text-[#FAEDD9] text-xl" />
                  <div
                    className={`absolute top-[-10px] right-[-7px] w-4 h-4 text-xs font-medium rounded-full flex items-center justify-center cursor-pointer hover:scale-110 transition-all duration ${
                      cartQuantity === 0
                        ? "invisible"
                        : "bg-red-500 text-slate-100 shadow-slate-900"
                    }`}
                  >
                    {cartQuantity}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:block hidden">
          <div
            style={{ backgroundImage: `url(${Headrerimage})` }}
            className="w-full h-[80px] flex   items-center text-xl text-white "
          >
            <div className="flex justify-end w-[60%] gap-5">
              <div
                className={`${activeLink === "home" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("home")}
              >
                <Link to="/">Home</Link>
              </div>
              <div
                className={`${activeLink === "about" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("about")}
              >
                <Link to="/about">About Us</Link>
              </div>
              <div
                className={`${activeLink === "product" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("product")}
              >
                <Link to="/product">Products</Link>
              </div>
              <div
                className={`${activeLink === "contact" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("contact")}
              >
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>

            <div className="w-[40%] flex items-center gap-4 justify-center">
              <div onClick={handleSearchClick}>
                <SearchOutlined className="text-3xl text-[#FAEDD9] cursor-pointer" />
              </div>
              <div onClick={handleCartPage} className="relative">
                <img
                  className="w-[30px] cursor-pointer"
                  src={cart}
                  alt="Cart"
                />
                <div
                  className={`absolute top-[-15px] right-[-10px] w-5 h-5 text-xs font-medium rounded-full flex items-center justify-center cursor-pointer hover:scale-110 transition-all duration ${
                    cartQuantity === 0
                      ? "invisible"
                      : "bg-red-500 text-slate-100 shadow-slate-900"
                  }`}
                >
                  {cartQuantity}
                </div>
              </div>
              {/* onClick={handleClick} */}
              <div>
                {/* <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={openProfile}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <img
                      className="w-[30px] cursor-pointer"
                      src={user}
                      alt="User"
                    />
                  </a>
                </Dropdown> */}
              </div>
              {/* {isPopUpOpen && <Login handleModal={handleModal} />} */}
              <button onClick={showModal}>Login</button>

              <Modal
                className=" "
                footer={[]}
                // width={400}
                visible={isModalOpen}
                onCancel={handleCancel}
              >
                <div className="flex justify-center bg-primaryColor">
                  <img className="w-[30%] h-[30%]" src={logo1} alt="Logo" />
                </div>
                {!isSignupModalOpen?<div className="flex flex-col gap-3 my-3">
                  <>
                    <label>Email/Mobile Number</label>
                    <input className="rounded-full p-2 border" />
                    <label>Password</label>
                    <Input
                      type={visible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="border p-2 rounded-full"
                      autoComplete="off"
                      suffix={
                        <Button
                          type="text"
                          onClick={handleToggleVisibility}
                          icon={
                            visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                          }
                        />
                      }
                    />{" "}
                    <p
                      className="flex justify-start cursor-pointer"
                      onClick={toggleForgotPasswordModal}
                    >
                      Forgot Password?
                    </p>
                    <button className="bg-popButtoncolour rounded-full p-2 text-white text-lg">
                      Login
                    </button>
                    <p className="text-center">
                      Don't have an account?{" "}
                      <span
                        className="text-popButtoncolour cursor-pointer"
                        onClick={showModal2}
                      >
                        Sign up
                      </span>
                    </p>
                  </>
                </div>:
                <div>
                  <p className="text-xl mt-2">Sign Up</p>
                  <div className="grid mt-2">
                    {showPasswordFields && (
                      <>
                        <label htmlFor="password" className="mt-2">
                          Password
                        </label>
                        <Input
                          type={visible ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="border p-2 rounded-full"
                          autoComplete="off"
                          suffix={
                            <Button
                              type="text"
                              onClick={handleToggleVisibility}
                              icon={
                                visible ? (
                                  <EyeInvisibleOutlined />
                                ) : (
                                  <EyeOutlined />
                                )
                              }
                            />
                          }
                        />{" "}
                        <label htmlFor="confirmPassword" className="mt-2">
                          Confirm Password
                        </label>
                        <Input
                          type={visible1 ? "text" : "password"}
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                          className="border p-2 rounded-full"
                          autoComplete="off"
                          suffix={
                            <Button
                              type="text"
                              onClick={handleToggleVisibility1}
                              icon={
                                visible1 ? (
                                  <EyeInvisibleOutlined />
                                ) : (
                                  <EyeOutlined />
                                )
                              }
                            />
                          }
                        />{" "}
                        <p className="p-2 text-center mt-1">
                          Ensure your password is 8-16 characters long and
                          contains at least one uppercase letter, one numeral,
                          and one special character
                        </p>
                      </>
                    )}

                    {!showPasswordFields && (
                      <>
                        <label htmlFor="name">Name</label>
                        <input
                          id="name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="border p-2 mt-2 rounded-full"
                        />

                        <label htmlFor="mobile">Mobile Number</label>
                        <input
                          id="mobile"
                          type="text"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          className="border p-2 mt-2 rounded-full"
                        />

                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="border p-2 mt-2 rounded-full"
                        />

                        <button
                          className="border rounded-full p-2 mt-4 bg-popButtoncolour text-white"
                          onClick={handleNextClick}
                        >
                          Next
                        </button>
                      </>
                    )}

                    {showPasswordFields && (
                      <button className="border rounded-full p-2 mt-4 bg-popButtoncolour text-white">
                        Submit
                      </button>
                    )}
                  </div>
                </div>}
              </Modal>
              <Modal
                title=""
                className="text-lg"
                visible={isForgotPasswordModalOpen}
                onCancel={toggleForgotPasswordModal}
                footer
              >
                <div>
                  <div className="flex justify-center bg-primaryColor">
                    <img className="w-[30%] h-[30%]" src={logo1} alt="Logo" />
                  </div>
                  <div className="grid">
                    <p className="text-xl mt-2">Forgot Password</p>
                    <label className="mt-2">Email</label>
                    <input
                      type="text"
                      className="rounded-full p-2 border mt-2"
                    />
                    <label className=" mt-4">OTP</label>
                    <div className="flex mt-2">
                      {[1, 2, 3, 4, 5, 6].map((_, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          className="rounded-full p-2 border mr-2 w-full text-center"
                        />
                      ))}
                    </div>
                    <button className="w-full rounded-full border bg-popButtoncolour text-white mt-4 p-2">
                      Next
                    </button>
                    <p className="mt-3 text-center">
                      Remember Password?{" "}
                      <span
                        className="cursor-pointer text-popButtoncolour"
                        onClick={toggleLoginModal}
                      >
                        Login
                      </span>
                    </p>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        {isSearchOpen && (
          <div
            className={`search-box flex justify-center items-center   h-[80px] bg-popUpgreen z-10 text-center  w-full ${
              isHeaderFixed ? "top-0" : "top-10"
            }`}
          >
            <div className="relative bg-transparent">
              <Select
                showSearch
                allowClear
                onChange={handleSearch}
                onSearch={onSearchp}
                placeholder="Search..."
                className=" w-[60vw] rounded h-[41px] bg-popUpgreen  outline-none  border-2 border-primaryColor "
                options={productSearch.map((hc) => ({
                  value: hc.id,
                  label: hc.name,
                  key: hc.id,
                }))}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              {/* <input
                type="text"
                placeholder="Search..."
                className="my-8 w-[60vw] rounded h-[42px] text-slate-900 outline-none px-2 border-2 border-slate-800"
              /> */}

              {/* <button>
                <SearchOutlined
                  className="absolute top-[32px] py-2 px-4 right-[0px] rounded-tr text-[25px] text-white cursor-pointer rounded-br bg-primaryColor"
                />
              </button> */}
            </div>
            <button
              className="mx-5 text-slate-800 p-2 rounded-lg my-[30px] h-[40px]"
              onClick={handleSearchClose}
            >
              <CloseOutlined />
            </button>
          </div>
        )}
      </div>

      <Drawer
        className="drawerclose"
        title={
          <div className="">
            <img src={logo} alt="Logo" />
          </div>
        }
        placement="left"
        onClose={onClose}
        open={open}
      >
        <div className="flex flex-col gap-4  text-base cursor-pointer ">
          <div
            className={`${activeLink === "home" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("home")}
          >
            <Link to="/">Home</Link>
          </div>
          <div
            className={`${activeLink === "about" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("about")}
          >
            <Link to="/about">About Us</Link>
          </div>
          <div
            className={`${activeLink === "product" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("product")}
          >
            <Link to="/product">Products</Link>
          </div>
          <div
            className={`${activeLink === "contact" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("contact")}
          >
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;