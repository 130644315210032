import React, { useEffect, useState } from 'react';
import Layouts from '../../Layouts';
import Npim from '../../../Assests/images/Npim.png';
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import Card from './Card';
// import productsData from '../../../sampleData/Productdata';
import Filter from './Filter';
import Sort from './Sort';
import Pagination from './Pagination';
import { Select } from 'antd';
import ProductService from '../../../Redux/Services/ProductService';


function Product() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isSortopen, setIsSortopen] = useState(false);
  const [currCart, setCurrCart] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [showThreeCards, setShowThreeCards] = useState(false);
  const [productsData, setProducts] = useState([]);
  const [currSelectedProduct, setCurrSelectedProduct] = useState([]);
  const [totalProductCount, setTotalProductCount] = useState();
  const [range, setRange] = useState(0);

  const itemsPerPage = showThreeCards ? 6 : 8;

  const ProductListData = async () => {
    try {
      let payload = {
        status: 1,
      };
      const result = await ProductService.ProductList(payload);
      setProducts(result.data);
      const itemsPerPage = showThreeCards ? 6 : 8;
      const startIdx = currentPage * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
      const items = result.data.slice(startIdx, endIdx);
      setCurrSelectedProduct(items);
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };
  
  useEffect(() => {
    ProductListData();
  }, []);
  
  useEffect(() => {
    const itemsPerPage = showThreeCards ? 6 : 8;
    const startIdx = currentPage * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    if (range !== 0) {
      const filteredData = productsData.filter((item) => item.price < range);
      const itemsPerPage = showThreeCards ? 6 : 8;
      const startIdx = currentPage * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
      const items = filteredData.slice(startIdx, endIdx);
      const total = filteredData.length;
      setTotalProductCount(total);
      console.log("price filtered item ", items);
      setCurrSelectedProduct(items);
    }
    else if (selectedCategory === 'default' || selectedCategory === '') {
      const items = productsData.slice(startIdx, endIdx);
      const total = productsData.length;
      console.log(total);
      setTotalProductCount(total);
      setCurrSelectedProduct(items);
    }
    // else if (selectedCategory) {
    //   const items = productsData.filter((product) => product.productCategoryCode === selectedCategory);

    //   console.log("Filtered Items ", items);
    //   console.log("products data ", productsData);
    //   const total = items.length;
    //   setTotalProductCount(total);
    //   const slicedItem = items.slice(startIdx, endIdx);
    //   setCurrSelectedProduct(slicedItem);
    // }
  }, [currentPage, showThreeCards]);


  const handlePagination = (page) => {
    setCurrentPage(page - 1);
  }

  const handleCartPopUpChange = (number) => {
    setCurrCart(number);
  }

  const handlePriceChange = (e) => {
    setRange(e.target.value);
    const filteredData = productsData.filter((item) => item.price[0] < e.target.value);
    const itemsPerPage = showThreeCards ? 6 : 8;
    const startIdx = currentPage * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const items = filteredData.slice(startIdx, endIdx);
    const total = filteredData.length;
    setTotalProductCount(total);
    console.log("price filtered item ", items);
    setProducts(items);
  }

  const handleSort = (sortType) => {
    const { id } = sortType;
    if (id === 1) {
      productsData.sort((a, b) => a.name.localeCompare(b.name))
    }
    else if (id === 2) {
      productsData.sort((a, b) => b.name.localeCompare(a.name))
    }
    else if (id === 3) {
      productsData.sort((a, b) => a.price[0] - b.price[0])
    }
    else if (id === 4) {
      productsData.sort((a, b) => b.price[0] - a.price[0])
    }
    const itemsPerPage = showThreeCards ? 6 : 8;
    const startIdx = currentPage * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const items = productsData.slice(startIdx, endIdx);
    const total = productsData.length;
    setTotalProductCount(total);
    setProducts(items);
  }

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleDropdownToggle = () => {
    setIsSortopen((prevState) => !prevState);
  };


  const handleShowThreeCards = () => {
    setShowThreeCards((prevState) => !prevState);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setTimeout(() => {
      toggleDropdown();
    }, 100);
  };

  const handleChange = async (value) => {
    console.log(value);
    setSelectedCategory(value);

    let payload = {
      status: 1,
    };
    if(value!== "default"){
      payload.productCategoryCode =value
    }
    await ProductService.ProductList(payload).then((result) => {
      setProducts(result.data);
      const itemsPerPage = showThreeCards ? 6 : 8;
      const startIdx = currentPage * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
      const items = result.data.slice(startIdx, endIdx);
      setCurrSelectedProduct(items);
    });


  }

  const fetProductDetails = async (code) => {
    const response = await ProductService.productInvetoryData({ productCode: code });
    console.log(response.data);
    return response.data;
  }

  return (
    <Layouts>
      <div>
        <div
          className="py-[50px]  w-full bg-cover"
          style={{ backgroundImage: ` url(${Npim})` }}
        >
          <div className="container mx-auto">
            <h1 className="leading-tight font-light md:text-3xl mb-3 ml-5 text-[#FFF1DC]">
              Get authentic
              <br />
              <span className="font-semibold">Aurvedic Solutions</span>
              <br />
              at a Click
            </h1>
          </div>
        </div>

        <div className='bg-popUpgreen'>
          <div className='container  mx-auto relative '>
            <div className='flex justify-between md:mx-10 p-3 border-b-slate-700 border-b '>
              <div className='flex items-center md:gap-12 gap-3'>
                <div className='flex md:gap-4 gap-2 items-center'>
                  <p className='md:text-sm text-sm'>FILTER BY:</p>
                  {/* <div className='flex md:text-sm text-xs items-center md:border-2 border-1 border-stone-500 md:px-2 rounded-md text-center' onClick={toggleDropdown}>
                    All Products <MdKeyboardArrowDown />
                  </div> */}
                  <Select className='w-[7rem] h-[1.5rem] bg-' placeholder="ALL" onChange={handleChange}>
                    <Select.Option value="default">ALL</Select.Option>
                    <Select.Option value="Kashayam">Kashayam</Select.Option>
                    <Select.Option value="Churnams">Churnams</Select.Option>
                    <Select.Option value="Tailam">Tailam</Select.Option>
                    <Select.Option value="Ghrithms">Ghrithms</Select.Option>
                    <Select.Option value="Lehams">Lehams</Select.Option>
                  </Select>
                </div>
                <div className='flex md:gap-4 gap-2'>
                  <p className='md:text-sm text-sm'>SORT BY:</p>

                  <div className='flex md:text-sm text-xs items-center md:border-2 border-1 border-stone-500  px-2 rounded-md' onClick={handleDropdownToggle}>
                    Featured <MdKeyboardArrowDown />
                  </div>

                </div>
              </div>
              <div className='hidden md:block' onClick={() => setShowThreeCards(prevState => !prevState)} style={{ borderRadius: '50%', padding: '2px', border: '0.5px solid black', cursor: 'pointer' }}>
                <div style={{ borderRadius: '50%', overflow: 'hidden', height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {showThreeCards ? <TfiLayoutGrid4Alt style={{ width: '20px', height: '20px' }} /> : <BsGrid3X3GapFill style={{ width: '20px', height: '20px' }} />}
                </div>
              </div>
            </div>
            {/* {isDropdownOpen && <Filter handleCategorySelect={handleCategorySelect} />} */}
            {isSortopen && <Sort handleDropdownToggle={handleDropdownToggle} handleSort={handleSort} handlePriceChange={handlePriceChange} range={range} />}
            <div className={`flex flex-wrap justify-center mt-5 ${showThreeCards ? 'gap-[4rem]' : 'gap-[2rem]'}`}>
              {
                currSelectedProduct &&
                currSelectedProduct.map((item, idx) => {

                  return (
                    <Card key={idx} product={item} productInfo={async () => await fetProductDetails(item.code)} currCart={currCart} showThreeCards={showThreeCards} handleCartPopUpChange={handleCartPopUpChange} />
                  )
                }
                )}
            </div>
          </div>
          <Pagination handlePagination={handlePagination} Size={totalProductCount} itemsPerPage={itemsPerPage} />
        </div>

      </div>

    </Layouts>
  );
} export default Product