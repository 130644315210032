import React from 'react'
import Layouts from '../Layouts'
import { Divider, Input, Space } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const ChangePassword = () => {

  return (
    <Layouts >
      <div className='flex justify-center'>
        <div className='w-[50%] mx-5 my-5 flex flex-col gap-4  bg-slate-50  p-5 shadow-lg rounded-lg justify-center'>
          <p className='font-bold text-2xl'>Change Your Password</p>
          <p className='text-xl font-medium'>Please fill the following details</p>
          <Divider className='' />
          <div className='flex '>
            <div className='w-[50%] flex flex-col gap-3'>
              <div className=' flex flex-col w-[80%] gap-2'>
                <p className='font-bold text-base'>Old Password</p>
                <Space direction="vertical">
                  <Input.Password className='p-2 text-base'
                    placeholder="Type Old Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Space>
              </div>
              <div className=' flex flex-col w-[80%] gap-2'>
                <p className='font-bold text-base'>New Password</p>
                <Space direction="vertical">
                  <Input.Password className='p-2 text-base'
                    placeholder="Type New Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Space>
              </div>
              <div className=' flex flex-col w-[80%] gap-2'>
                <p className='font-bold text-base'>Confirm Password</p>
                <Space direction="vertical">
                  <Input.Password className='p-2 text-base'
                    placeholder="Type Confirm password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Space>
              </div>

              <button className='w-[80%] p-2 rounded-md bg-popButtoncolour text-white tex border'>Change Password</button>

            </div>
            <div className='w-[50%] border shadow-md rounded-md p-3'>
              <p className='font-bold text-xl'>Password Must contain:</p>

              <Divider />
              <div className='flex flex-col gap-2 text-lg'>
                <li>Must contain a min of 8 and max of 16 character</li>
                <li>Must contain at least one uppercase and a numeral</li>
                <li>Must contain at least one special character</li>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layouts>
  )
}

export default ChangePassword
