import React, { useEffect, useRef, useState } from 'react';
import Layouts from '../../Layouts';
import ProductList from './ProductList';
import ProductCategories from './ProductCategories';
// import productsData from '../../../sampleData/Productdata';
import line from '../../../Assests/images/line.png'
import radio from '../../../Assests/images/Button.png'
import BannerImg from '../../../Assests/images/Banner.png';
import features from "../../../Assests/images/Features.png"
import ingredients from '../../../Assests/images/natural Ingredients.png'
import Holistic from '../../../Assests/images/Holistic Approach.png'
import Personalized from '../../../Assests/images/Personalized Care.png'
import Balance from '../../../Assests/images/Promotion of Balance.png'
import TimeTested from '../../../Assests/images/Time-Tested Tradition.png'
import Complementary from '../../../Assests/images/Complementary Healthcare.png'
import herbes from '../../../Assests/images/Herbs.png'
import Slider from "react-slick";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import testimonial1 from '../../../Assests/images/testimonial1.jpeg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prev from '../../../Assests/images/Prev.png'
import next from '../../../Assests/images/Next.png'
import ProductService from '../../../Redux/Services/ProductService';

const Home = () => {
  const [currType, setCurrType] = useState('Churnams');
  const [currProductList, setCurrProductList] = useState([]);
  const [productsData, setProducts] = useState([]);


  // const getProductList = () => {
  //   Authentication.AthenticationToken().then((res) => {
  //     console.log("res", res)
  //     localStorage.setItem("access_token", res.data.access_token);
  //   })
  // }

 

  const ProductListData = async () => {
    try {
      let payload = {
        status: 1,
      };
      const result = await ProductService.ProductList(payload);
      setProducts(result.data);
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };
  
  useEffect(() => {
    ProductListData();
  }, []);
  

  const targetRef = useRef(null);

  const scrollToDiv = () => {
    // Scroll to the target div
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    const filteredItemList = productsData.filter((item) => item.productCategoryCode === 'Churnams');
    setCurrProductList(filteredItemList);
  }, [productsData])

  useEffect(() => {
    const filteredItemList = productsData.filter((item) => item.productCategoryCode === currType);
    setCurrProductList(filteredItemList);
  }, [currType])

  const handleProductCategories = (category) => {
    scrollToDiv()
    setCurrType(category);
    console.log(category);
  }


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const bottom = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    prevArrow: <img src={prev} alt='Icons' />,
    nextArrow: <img src={next} alt='Icons' />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <>
      <Layouts>

        <div className='min-h-screen '>
          <section className='min-h-[85vh]  bg-cover bg-no-repeat bg-center py-[50px] relative' style={{ backgroundImage: `url(${BannerImg})` }}>
            <img className=' w-full h-2 absolute top-0' src={line} alt='1' />

            <div className='container mx-auto py-10 md:p-0 p-4'>
              <h1 className='md:text-5xl text-3xl text-textWhite font-base mb-3'>
                DISCOVER THE NATURAL
              </h1>
              <h1 className='md:text-5xl text-3xl text-textWhite font-base mb-3'>PATH TO WELL-BEING</h1>
              <p className='md:w-[50%] text-justify  text-textWhite font-light'>
                Embark on a journey of holistic wellness with us. We blend ancient wisdom with modern expertise to offer you personalized pathways to health and vitality. Explore our range of Ayurvedic therapies and herbal remedies tailored to rejuvenate your mind, body, and spirit.
              </p>
            </div>
            <img className=' w-full h-2 absolute bottom-0' src={line} alt='1' />
          </section>
          <section className="bg-[#FBF3E6]">
            <div className="container mx-auto md:p-0 p-4 py-[50px] md:py-[90px]">
              <div className="md:flex justify-between items-center">
                <div className="md:w-[60%]">
                  <h1 className="text-3xl font-base text-secondaryColor mb-4">VENKATARAMANA AYURVEDA</h1>
                  <h6 className="text-primaryColor font-medium md:text-2xl text-lg mb-2">Who we are</h6>
                  <p className=" text-justify mb-4 md:w-[80%]">Venkataramana Ayurveda harnesses Ayurvedic wisdom for comprehensive wellness. With experienced practitioners and authentic therapies, we empower individuals to achieve balance and vitality naturally. Where ancient wisdom meets modern wellness, guiding you to a harmonious life through the transformative power of Ayurveda.
                  </p>
                  <div >
                    <ul className="md:grid grid-cols-2 gap-2 ">
                      <li className="flex gap-2  items-center"><img className='h-4 w-4 mt[3px]' src={radio} alt='/' />
                        Premium Branding</li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' /> Guaranteeing Punctual Delivery</li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' /> 100% Natural</li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' /> Ensuring Rigorous Quality Assurance</li>
                    </ul>
                  </div>

                </div>
                <div className="md:w-[40%] md:h-[350px] md:p-0 p-4">
                  <img src={herbes} alt="" className="w-full h-full object-contain" />
                </div>
              </div>
            </div>
          </section>
          <ProductCategories currType={currType} handleProductCategories={handleProductCategories} />

          <section ref={targetRef} className="bg-[#FBF3E6]">
            <div className="container mx-auto py-10 md:p-0 p-4">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-5">

                <ProductList currProductList={currProductList} />
              </div>
            </div>
          </section>
          <section className="relative bg-secondaryColor">
            <img className=' w-full h-2 absolute top-0' src={line} alt='1' />
            <div className="container mx-auto text-center py-20">
              <h1 className="text-2xl text-textWhite font-medium mb-2">OUR FEATURES</h1>
              <p className="md:text-2xl text-lg text-primaryColor font-base">Why Venkataramana Ayurveda?</p>
            </div>
            <img className=' w-full h-2 absolute bottom-0' src={line} alt='1' />
          </section>
          <section className='min-h-[85vh]  bg-cover bg-no-repeat bg-center py-[50px] md:p-0 p-4 relative' style={{ backgroundImage: `url(${features})` }}>
            <div className="container mx-auto">
              <div className="md:w-[80%] md:text-xl text-lg text-textWhite font-base">
                <p className=''>Ayurveda, a traditional system of medicine originating from ancient India, offers a a holistic approach to health and wellness. There are several reasons why people choose to use Ayurvedic products:</p>
              </div>
              <div className="md:px-10 my-10">
                <ul className="text-textWhite font-base  mt-10 flex flex-col gap-2">
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={ingredients} alt='/' />
                    <span className='font-normal md:w-[86%]  text-[16px]'>Natural Ingredients: Ayurvedic products s are typically formulated using natural ingredients such as herbis, roots, flowers, and oils. This emphasis on natural ingredients is appealing to those who prefer to avoid synthetic chemicals and additives.</span>
                  </li>
                  <li className="md:flex gap-4  items-center "> <img className='md:w-8 w-12 md:h-8 h-12' src={Holistic} alt='/' />
                    <span className='font-normal md:w-[86%] text-[16px]'>Holistic Approach: Ayurveda considers the interconnectedness of the body, mind, and spirit in achieving overall health and wellurvedic products often address not just the symptoms of a condition but also its underlying causes providing a holistic approach to health.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Personalized} alt='/' />
                    <span className='font-normal md:w-[86%] text-[16px]'> Personalized Care: Ayurveda recognizes that each individual is unique, and therefore, treatments and products are often tollored to suit a person's specific constitution (dosha) and needs. This personalized approach is appealing to those seeking customized healthcare solutions.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={TimeTested} alt='/' />
                    <span className='font-normal md:w-[86%] text-[16px]'>Time-Tested Tradition: Ayurveda has a rich history spanning thousands of years and has been passed down through generations. Many people trust Ayurvedic remedies due to their long history of use and the wealth of empirical evidence supporting their efficacy.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Balance} alt='/' />
                    <span className='font-normal md:w-[86%] text-[16px]'>Promotion of Balance: Ayurveda aims to restore balance to the body and mind by addressing imbalances in the doshas (Vata, Pitta, and Kapha). Ayurvedic products are formulated to help bring the doshas back into harmony, theretry promoting overall health and vitality.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Complementary} alt='/' />
                    <span className='font-normal md:w-[86%] w-24 text-[16px]'>Complementary Healthcare: Many people use Ayurvedic products alongside conventional medical treatments as a complementary approach to health and wellness. Ayurveda can often provide support for various health conditions and help manage symptoms.</span></li>
                  <li></li>
                </ul>
              </div>
              <div className="md:w-[80%] text-lg text-textWhite font-base">
                <p className=''>Overall, the use of Ayurvedic products aligns with a growing trend towards natural, holistic, and personalized approaches to health and wellness.</p>
              </div>
            </div>
           

          </section>
          <section className='bg-[#FBF3E6]'>
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                Testimonials <span className="text-secondaryColor"></span>
              </h2>


              <div className='grid grid-cols-1 gap-3'>
                <Slider {...bottom} >

                  <div>
                    <div className="bg-white rounded-2xl p-4 mx-3 ">
                      <div className="mb-5 flex justify-center ">
                        <img
                          src={testimonial1}
                          alt="images"
                          className="h-[200px] w-[200px] "
                        />
                      </div>
                      <p className="text-base leading-loose text-textGray h-[200px] overflow-y-auto" id="unquie-scroll">
                        I live on the outskirts of Kundhei and last week, I was
                        looking for medicine unavailable at any nearby local
                        pharmacy. it was when one of my neighbours suggested the
                        Venkataramana Ayurveda. Recently I contacted their e-clinic in
                        my area for teleconsultation. Their team helped me
                        teleconsult with the leading heart specialist. The
                        medicine order can be placed directly on phone calls and
                        WhatsApp. The medicines are delivered to the nearest
                        e-clinic. I collected it from there.
                      </p>
                      {/* <div className="text-center mt-5">
                          <p className="text-base md:text-lg text-primaryColor font-semibold mb-0">
                            Shita Bagchi
                          </p>
                          <p className="text-sm text-textGray">Bhubaneswar</p>
                        </div>  */}
                    </div>
                  </div>

                  <div>
                    <div className="bg-white rounded-2xl p-4 mx-3">
                      <div className="mb-5 flex justify-center">
                        <img
                          src={testimonial1}
                          alt="images"
                          className="h-[200px] w-[200px] "
                        />
                      </div>
                      <p className=" text-base leading-loose text-textGray h-[200px] overflow-y-auto" id="unquie-scroll">
                        I live on the outskirts of Kundhei and last week, I was
                        looking for medicine unavailable at any nearby local
                        pharmacy. it was when one of my neighbours suggested the
                        Venkataramana Ayurveda. Recently I contacted their e-clinic in
                        my area for teleconsultation. Their team helped me
                        teleconsult with the leading heart specialist. The
                        medicine order can be placed directly on phone calls and
                        WhatsApp. The medicines are delivered to the nearest
                        e-clinic. I collected it from there.
                      </p>
                      {/* <div className="text-center mt-5">
                          <p className="text-base md:text-lg text-primaryColor font-semibold mb-0">
                            Shita Bagchi
                          </p>
                          <p className="text-sm text-textGray">Bhubaneswar</p>
                        </div> */}
                    </div>
                  </div>

                  <div>
                    <div className="bg-white rounded-2xl p-4 mx-3">
                      <div className="mb-5 flex justify-center">
                        <img
                          src={testimonial1}
                          alt="images"
                          className="h-[200px] w-[200px] "
                        />
                      </div>
                      <p className=" text-base leading-loose text-textGray h-[200px] overflow-y-auto" id="unquie-scroll">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod ad debitis asperiores, nesciunt ullam eveniet, incidunt placeat enim aliquid culpa ratione, tempore corporis expedita animi totam numquam earum neque soluta amet! Debitis nemo architecto dignissimos eius laboriosam itaque tempora cupiditate!  quam error maxime odit, molestias quos neque veniam cumque sequi blanditiis expedita perspiciatis! Hic soluta sequi, iste corrupti itaque </p>
                      {/* <div className="text-center mt-5">
                          <p className="text-base md:text-lg text-primaryColor font-semibold mb-0">
                            Shita Bagchi
                          </p>
                          <p className="text-sm text-textGray">Bhubaneswar</p>
                        </div> */}
                    </div>
                  </div>


                  <div>
                    <div className="bg-white rounded-2xl p-4 mx-3">
                      <div className="mb-5 flex justify-center">
                        <img
                          src={testimonial1}
                          alt="images"
                          className="h-[200px] w-[200px] "
                        />
                      </div>
                      <p className=" text-base leading-loose text-textGray h-[200px] overflow-y-auto" id="unquie-scroll">
                        I live on the outskirts of Kundhei and last week, I was
                        looking for medicine unavailable at any nearby local
                        pharmacy. it was when one of my neighbours suggested the
                        Venkataramana Ayurveda. Recently I contacted their e-clinic in
                        my area for teleconsultation. Their team helped me
                        teleconsult with the leading heart specialist. The
                        medicine order can be placed directly on phone calls and
                        WhatsApp. The medicines are delivered to the nearest
                        e-clinic. I collected it from there.
                      </p>
                      {/* <div className="text-center mt-5">
                          <p className="text-base md:text-lg text-primaryColor font-semibold mb-0">
                            Shita Bagchi
                          </p>
                          <p className="text-sm text-textGray">Bhubaneswar</p>
                        </div> */}
                    </div>
                  </div>
                </Slider>
              </div>






            </div>
          </section>
        </div>
      </Layouts>

    </>
  );
};

export default Home;